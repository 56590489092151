import { Fab, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react'

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        zIndex: 2,
        backgroundColor: "#25d366",
        color: "white",
        '&:hover': {
            backgroundColor: "#128c7e",
            color: "white",
        }
    }
}));
const WhatsappFab = () => {
    const classes = useStyles();
    return (
        <Fab className={classes.fab} target="_blank" rel="noopener" href="https://wa.me/51959672804/?text=Hola,%20te%20escribo%20desde%20la%20web" >
            <Icon className={"icon-fontawesome fab fa-whatsapp"} />
        </Fab>
    )
}

export default WhatsappFab
