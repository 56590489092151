import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

// Images
import logo from '../../../assets/img/main_logo.png';

class FrontHeader extends Component {

  render() {
    return (
      <div>
        <div uk-sticky="show-on-up: true; animation: uk-animation-slide-top;" >
          <nav className="uk-navbar-container uk-navbar md-bg-blue-grey-800" uk-navbar="mode: click">
            <div className="uk-navbar-left">
              <div className=" uk-hidden@s">
                <a className="uk-navbar-toggle" uk-toggle="target: #offcanvas-nav-primary"><i className="material-icons md-icon md-color-white">menu</i></a>
              </div>
              <a className="uk-navbar-item uk-logo" href="#"><img src={logo} width="240px" /></a>
            </div>
            <div className="uk-navbar-right  uk-visible@s">
              <ul className="uk-navbar-nav">
                <li><NavLink className="md-color-white" to="/">Inicio</NavLink></li>
                <li><NavLink className="md-color-white" to="/nosotros">Nosotros</NavLink></li>
                <li><NavLink className="md-color-white" to="/servicios">Servicios</NavLink></li>
                <li><NavLink className="md-color-white" to="/propiedades/0/0/1">Propiedades</NavLink></li>
                <li><NavLink className="md-color-white" to="/contacto">Contacto</NavLink></li>
              </ul>
            </div>

          </nav>
        </div>
        <div id="offcanvas-nav-primary" uk-offcanvas="overlay: true">
          <div className="uk-offcanvas-bar uk-flex uk-flex-column">
            <ul className="uk-nav uk-nav-primary uk-nav-center uk-margin-auto-vertical">
              <li><NavLink to="/">Inicio</NavLink></li>
              <li><NavLink to="/nosotros">Nosotros</NavLink></li>
              <li><NavLink to="/servicios">Servicios</NavLink></li>
              <li><NavLink to="/propiedades/0/0/1">Propiedades</NavLink></li>
              <li><NavLink to="/contacto">Contacto</NavLink></li>
            </ul>
          </div>
        </div>

      </div>
    )
  }
}
export default FrontHeader;