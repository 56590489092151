import React, { Component } from 'react';

// Components
import FrontHeader from '../Header/Header';
import FrontFooter from '../Footer/Footer';

// Images
import portada1 from '../../../assets/img/portada_1.jpg';
import mercado_inmobiliario from '../../../assets/img/mercado-inmobiliario.jpg';
import PageHeader from '../Components/PageHeader';

class Servicios extends Component {
  render() {
    return (
      <div>
        <FrontHeader />
        <PageHeader title="Servicios" />
        <div className="uk-section md-bg-blue-grey-50">
          <div className="uk-container">
            <div data-uk-grid>
              <div className="uk-width-1-3@m">
                <div className="uk-grid-small uk-flex-middle uk-grid uk-card uk-padding-small md-bg-white" data-uk-grid>
                  <div className="uk-width-auto uk-first-column">
                    <i className='material-icons md-color-teal-900'>check</i>
                  </div>
                  <div className="uk-width-expand">
                    <h3 className="uk-card-title uk-margin-remove-bottom">Compra</h3>
                  </div>
                </div>
              </div>
              <div className="uk-width-1-3@m">
                <div className="uk-grid-small uk-flex-middle uk-grid uk-card uk-padding-small md-bg-white" data-uk-grid>
                  <div className="uk-width-auto uk-first-column">
                    <i className='material-icons md-color-teal-900'>check</i>
                  </div>
                  <div className="uk-width-expand">
                    <h3 className="uk-card-title uk-margin-remove-bottom">Venta</h3>
                  </div>
                </div>
              </div>
              <div className="uk-width-1-3@m">
                <div className="uk-grid-small uk-flex-middle uk-grid uk-card uk-padding-small md-bg-white" data-uk-grid>
                  <div className="uk-width-auto uk-first-column">
                    <i className='material-icons md-color-teal-900'>check</i>
                  </div>
                  <div className="uk-width-expand">
                    <h3 className="uk-card-title uk-margin-remove-bottom">Tasaciones</h3>
                  </div>
                </div>
              </div>
              <div className="uk-width-1-3@m">
                <div className="uk-grid-small uk-flex-middle uk-grid uk-card uk-padding-small md-bg-white" data-uk-grid>
                  <div className="uk-width-auto uk-first-column">
                    <i className='material-icons md-color-teal-900'>check</i>
                  </div>
                  <div className="uk-width-expand">
                    <h3 className="uk-card-title uk-margin-remove-bottom">Asesoramiento Financiero</h3>
                  </div>
                </div>
              </div>
              <div className="uk-width-1-3@m">
                <div className="uk-grid-small uk-flex-middle uk-grid uk-card uk-padding-small md-bg-white" data-uk-grid>
                  <div className="uk-width-auto uk-first-column">
                    <i className='material-icons md-color-teal-900'>check</i>
                  </div>
                  <div className="uk-width-expand">
                    <h3 className="uk-card-title uk-margin-remove-bottom">Legal y Documentaria</h3>
                  </div>
                </div>
              </div>
              <div className="uk-width-1-3@m ">
                <div className="uk-grid-small uk-flex-middle uk-grid uk-card uk-padding-small md-bg-white" data-uk-grid>
                  <div className="uk-width-auto uk-first-column">
                    <i className='material-icons md-color-teal-900'>check</i>
                  </div>
                  <div className="uk-width-expand">
                    <h3 className="uk-card-title uk-margin-remove-bottom">Verificación y Asesoria</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-section uk-section-muted">
          <div className="uk-container">
            <div data-uk-grid>
              <div className="uk-width-1-2@m">
                <img src={mercado_inmobiliario} alt=""/>
              </div>
              <div className="uk-width-1-2@m">
                <h3>Otros servicios</h3>
                <p align='justify'>Nuestro Staff de Profesionales, señalan con éxito las necesidades del mercado y las oportunidades de inversión, teniendo objetivos claros que permiten transformar su proyecto en una realidad.</p>
                <ul>
                  <li>Administración de inmuebles.</li>
                  <li>Marketing Inmobiliario.</li>
                  <li>Análisis de mercado.</li>
                  <li>Estrategia de comercialización.</li>
                  <li>Project Management.</li>
                  <li>Arquitectura – Proyectos – Refacciones.</li>
                  <li>Asesoramiento legal y financiamiento.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-section md-bg-blue-grey-50">
          <div className="uk-container">
            <div data-uk-grid>
              <div className="uk-width-1-2@m">
                <h3>Arequipa</h3>
                <p>Descubre mas acerca de nuestra hermosa ciudad</p>
              </div>
              <div className="uk-width-1-2@m">
              <iframe width="100%" height="320" src="https://www.youtube.com/embed/YEgSSw33hek" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>
          </div>
        </div>
        <FrontFooter />
      </div>
    )
  }
}
export default Servicios;