import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { setCurrentUser } from '../../../redux/user/user.actions';

import 'uikit/dist/css/uikit.min.css';
import UIkit from 'uikit/dist/js/uikit.js';
import MDUIkit from 'md-uikit';
import Logo from "../../../assets/img/main_logo_alt.png";
import AppSettings from '../../../app_settings';

class LoginPage extends Component {
    state = {
        isLogged: false,
        email: '',
        password: ''
    }
    onClickLogin = async event => {

        const { setCurrentUser } = this.props;
        const { email, password } = this.state;
        /* Validaciones */
        let flag = true;
        if (email === "") {
            flag = false;
        }
        if (password === "") {
            flag = false;
        }
        if (flag) {
            const button = document.querySelector("#btn_login");
            button.innerHTML = '<div data-uk-spinner></div> Cargando...';
            button.disabled = true;
            try {
                const data = {
                    "email": email,
                    "password": password,
                    "type": "email"
                };
                console.log("Login: ");
                console.log(data);
                let url = `https://inmobiliariamonicatejada.com/admin/API/login.php`;
                console.log(url);
                const response = await fetch(url, {
                    method: 'POST',
                    body: JSON.stringify(data)
                });
                if (response.status >= 400 && response.status < 500) {
                    UIkit.notification('Usuario y/o contraseña incorrectos.');
                    throw "Error" + response.status;
                }
                if (response.status >= 500 && response.status < 600) {
                    UIkit.notification('Error. Please try later.');
                }
                const res = await response.json();
                console.log(res);
                if (res.user) {
                    setCurrentUser({
                        'Id': res.user.id,
                        'Name': res.user.firstname + ' ' + res.user.lastname,
                        'FirstName': res.user.firstname,
                        'LastName': res.user.lastname,
                        'email': res.user.email,
                        'rol': res.user.rol,
                        'language': res.user.language,
                        'token': res.token
                    })
                }
                button.innerHTML = 'Iniciar Sesion';
                button.disabled = false;
            } catch (error) {
                button.innerHTML = 'Iniciar Sesion';
                button.disabled = false;
                console.log(error);
                UIkit.notification('Email y/o contraseña incorrecto(s)');
            }
        } else {
            UIkit.notification('Complete los espacios en blanco');
        }

    }
    componentDidMount() {
        MDUIkit.components();
    }
    handleChange = event => {
        const { value, name } = event.target;
        this.setState({ [name]: value })
    }
    render() {
        if (this.state.isLogged) {
            return <Redirect push to="/admin" />;
        }
        return (
            <div className="uk-padding" data-uk-grid>
                <div className="uk-width-1-3@s"></div>
                <div className="uk-width-1-3@s">
                    <div className="md-card">
                        <div className="md-card-content">
                            <img className="uk-align-center" src={Logo} width="80%" />
                            <div className="md-input-wrapper uk-width-1-1 uk-margin-small">
                                <label>User:</label>
                                <input name="email" type="text" className="md-input" onChange={this.handleChange} required value={this.state.email} />
                                <span className="md-input-bar"></span>
                            </div>
                            <div className="md-input-wrapper uk-width-1-1 uk-margin-small">
                                <label>Password:</label>
                                <input name="password" type="password" className="md-input" onChange={this.handleChange} required
                                    value={this.state.password} />
                                <span className="md-input-bar"></span>
                            </div>
                            <div className="uk-margin-medium-top">
                                <button onClick={this.onClickLogin}
                                    className="md-btn md-color-white md-btn-primary md-btn-block md-btn-large" id="btn_login">LOGIN</button>
                                {/* <button onClick={signInWithGoogle} className="md-btn md-color-white md-btn-primary md-btn-block md-btn-large">SIGN IN WITH GOOGLE</button> */}

                                <div id="ResultLogin"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
})
const mapDispatchToProps = dispatch => ({
    setCurrentUser: user => dispatch(setCurrentUser(user))
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);