
import React, { Component } from 'react';
import { Link } from "react-router-dom";
// Images
/* import AiSurLogo from '../../../assets/img/aisur.jpg'; */

import white_logo from '../../../assets/img/white_logo.png';
import fondo_rocas from '../../../assets/img/fondo_rocas.jpg';
import { Box, MenuItem, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AiSurLogo from '../../../assets/img/aisur.jpg';
import WhatsappFab from '../Components/WhatsappFab';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'grey',
      },/*
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      }, */
    },
    '& .MuiSelect-root': {
      color: "white"
    },
    '& .MuiSvgIcon-root': {
      color: "white"
    },
  },
})(TextField);

const Footer = () => {



  return (
    <>
      <div id="footer" className="uk-section-secondary" style={{ backgroundImage: `url(${fondo_rocas})`, backgroundSize: "cover" }}>
        <div className="uk-container" style={{ paddingTop: "30px" }}>
          <div className="uk-padding-small">
            <div className='' data-uk-grid>
              <div className='uk-width-1-4@m'>
                <div className="uk-text-center">
                  <img width='150px' src={white_logo} alt="" /></div>
              </div>
              <div className='uk-width-1-4@m'>
                <h4>
                  NOSOTROS
              </h4>
                <div>
                  <p>
                    Tenemos como objetivo serviles de ayuda en la busqueda de las mejores soluciones posibles para rentabilizar sus decisiones
                  </p>
                </div>
                <div>Edificio Magnus Torre B  Dpto 508  - Cayma</div>
                <div>Arequipa - Perú</div>
              </div>
              <div className='uk-width-1-4@m'>
                <h4>MENU</h4>
                {/*  <div><Link to=""></Link><a>CONTACT</a></div> */}
                <div>
                  <Link className="md-color-white" to="/">Inicio</Link>
                </div>
                <div>
                  <Link className="md-color-white" to="/nosotros">Nosotros</Link>
                </div>
                <div>
                  <Link className="md-color-white" to="/servicios">Servicios</Link>
                </div>
                <div>
                  <Link className="md-color-white" to="/propiedades/0/0/1">Propiedades</Link>
                </div>
                <div>
                  <Link className="md-color-white" to="/contacto">Contacto</Link>
                </div>
              </div>
              <div className='uk-width-1-4@m'>
                <Box>
                  <h4>Somos parte de</h4>
                  <img width='90px' src={AiSurLogo} alt="" />
                </Box>
                <Box p={2} align="center">
                  <span style={{ marginRight: "20px" }}>
                    <a href="https://wa.me/51959672804/?text=Hola,%20te%20escribo%20desde%20la%20web"><i className="fab fa-whatsapp md-color-white"></i></a>
                  </span>
                  <a href="https://www.facebook.com/CorretajeBienesRaicesMonicaTejadaD"><i className="fab fa-facebook-f md-color-white"></i></a>
                </Box>
                {/* <div><Link to="/shop/1">SHOP</Link></div>
                <div><Link to="/about">ABOUT US</Link></div>
                <div><Link to="/steps">HOW IT WORKS</Link></div>
                <div><Link to="/producers">PRODUCERS</Link></div>
                <div><Link to="/recipes">RECIPES</Link></div> */}
              </div>
              <div className="uk-width-3-4">
                All Rights Reserved.
            </div>
              <div className="uk-width-1-4" align="right">
              </div>
            </div>
          </div>
        </div>
      </div>
      <WhatsappFab />
    </>
  );
}
export default Footer;
