import React, { Component } from 'react';
import { GoogleMap, LoadScript, GroundOverlay, Marker, InfoWindow } from '@react-google-maps/api';
class Mapas extends Component {
    state = {
        width: window.innerWidth,
        map_height: '70vh',
    };
    static defaultProps = {
        center: {
            lat: -16.392278,
            lng: -71.549320
        },
        zoom: 21
    };
    componentWillMount() {
        console.log('will mount');
        window.addEventListener('resize', this.handleWindowSizeChange);

    }

    // make sure to remove the listener
    // when the component is not mounted anymore
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        console.log('resize');
        const width = window.innerWidth;
        const isMobile = width < 640;
        var map_height;
        if (isMobile) {
            map_height = window.innerHeight - 55
        } else {
            map_height = window.innerHeight
        }
        this.setState({ width: window.innerWidth, map_height: map_height });
    };
    setMyPos(pos) {
        this.setState({
            my_pos: {
                lat: pos.lat,
                lng: pos.lng
            }
        })
    }
    componentDidMount() {
        console.log('did mount');
        this.handleWindowSizeChange();
    }
    render() {
        const defaultMapOptions = {
            fullscreenControl: false,
        };
        return (
            // Important! Always set the container height explicitly
            <div className="page-content">
                <LoadScript
                    id="script-loader"
                    googleMapsApiKey='AIzaSyC94bXGU6DBk27FpVXCTxmBGM8dZMu7Lzc'
                >
                    <GoogleMap
                        id='example-map'
                        options={defaultMapOptions}
                        mapContainerStyle={{
                            height: this.state.map_height,
                            width: "100%"
                        }}
                        zoom={18}
                        center={{
                            lat: -16.392278,
                            lng: -71.549320
                        }}
                    >
                        
                    </GoogleMap>
                </LoadScript >
            </div >
        );
    }
}

export default Mapas;