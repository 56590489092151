import React, { Component } from 'react';
import MDUIkit from 'md-uikit';

import { Link } from "react-router-dom";
import AppSettings from '../../app_settings';

class Propiedades extends Component {
  state = {
    propertyInfo: [],
    propertyFeatures: [],
    propertyServices: [],
    propertyImages: [],
    loading: true
  }
  _fetchPropertyDetail({ propertyId }) {
    fetch(`${AppSettings.connectionString}/admin/API/script_getInmuebleById.php?InmuebleId=${propertyId}`)
      .then(res => res.json())
      .then(data => {
        this.setState({ propertyInfo: data, loading: false })
      })
  }
  _fetchPropertyImages({ propertyId }) {
    const req = {
      "inmuebleId": propertyId
    }
    fetch(`${AppSettings.connectionString}/admin/API/inmueble_images_by_id.php`, {
      method: 'POST',
      body: JSON.stringify(req)
    })
      .then(res => res.json())
      .then(data => {
        //console.log('script_getPropertyImages ' + propertyId + ': ');
        //console.log(data)
        this.setState({ propertyImages: data, loading: false })
      })
  }
  goBack = (e) => {
    e.preventDefault();
    console.log("GO back")
    this.props.history.goBack();
  }
  componentDidMount() {
    MDUIkit.components();
    //console.log(this.props);
    const { propiedadId } = this.props;
    //console.log(this.props.match.params);
    this._fetchPropertyDetail({ propertyId: propiedadId });
    this._fetchPropertyImages({ propertyId: propiedadId });
  }
  render() {
    const { propertyInfo, propertyImages } = this.state;
    return (
      <div className="uk-card md-bg-white">
        <div className="uk-card-media-top">
          <div className="uk-inline ">
            {propertyImages.length > 0 &&
              <img src={`${AppSettings.connectionString}/admin${propertyImages[0].imageUrl}`} alt="" />
            }
            <div className="uk-overlay-default uk-position-cover rgba-black-gradient"></div>
            <div className="uk-overlay uk-position-bottom uk-padding-small">
              <div className="uk-grid-collapse uk-flex-middle" data-uk-grid>
                <div className="uk-width-auto">
                  <h3 className="uk-card-title uk-margin-remove-bottom md-color-white">{propertyInfo.Titulo}</h3>
                  <p className="uk-text-meta uk-margin-remove-top uk-margin-remove-bottom md-color-grey-400">{propertyInfo.DistritoNombre} - {propertyInfo.DepartamentoNombre}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-card-body">
          <Link to={`/detalle_propiedad/${propertyInfo.InmuebleId}`}>
            <p align='justify' className="uk-text-meta uk-margin-remove-top uk-margin-remove-bottom md-color-blue-grey-700">
              <time>
                {propertyInfo.Descripcion && propertyInfo.Descripcion.length > 80 ?
                  propertyInfo.Descripcion.substring(0, 180) + "..." :
                  propertyInfo.Descripcion
                }
              </time>
            </p>
          </Link>
        </div>
        <div className="uk-card-footer">
          <div className="md-card-toolbar-actions">
            <Link to={`/detalle_propiedad/${propertyInfo.InmuebleId}`} className="md-btn md-btn-text md-btn-secondary md-btn-wave">VER MAS</Link>
          </div>
        </div>
      </div>
    )
  }
}
export default Propiedades;