import React, { Component } from 'react';
//import MDUIkit from 'md-uikit';
import UIkit from 'uikit';
import { Link, Redirect } from "react-router-dom";

import AppSettings from '../../../app_settings';

class Step2 extends Component {
  state = {
    loading: false,
    selected_services: [],
    unselected_services: [],
    updated_services: [],
    servicios_array: [],
    caracteristicas_array: [],
    propertyFeatures: [],
    propertyServices: [],
    propertyId: 0,
    nextStep: false
  }

  componentDidMount() {
    //MDUIkit.components();
    const { propiedadId } = this.props.match.params;
    this.setState({ propertyId: propiedadId })
    this._fetchCaracteristicas();
    this._fetchServicios();
    window.scrollTo(0, 0);
  }
  setCaracteristicasList() {
    console.log('setCaracteristicasList');
    var { caracteristicas_array, propertyFeatures } = this.state;
    caracteristicas_array.forEach(element => {
      for (let index = 0; index < propertyFeatures.length; index++) {
        if (propertyFeatures[index].CaracteristicaId === element.CaracteristicaId) {
          console.log(propertyFeatures[index].CaracteristicaId);
          element.checked = true;
          element.propertyFeature = true;
          break;
        } else {
          element.checked = false;
          element.propertyFeature = false;
        }
      }
    });
    console.log(caracteristicas_array);
    this.setState({ caracteristicas_array: caracteristicas_array });
  }
  setServiciosList() {
    var { servicios_array, propertyServices } = this.state;
    servicios_array.forEach(element => {
      for (let index = 0; index < propertyServices.length; index++) {
        if (propertyServices[index].CaracteristicaId === element.CaracteristicaId) {
          console.log(propertyServices[index].CaracteristicaId);
          element.checked = true;
          element.propertyFeature = true;
          element.value = propertyServices[index].Value;
          break;
        } else {
          element.checked = false;
          element.propertyFeature = false;
        }
      }
    });
    this.setState({ servicios_array: servicios_array })
  }
  _renderCaracteristicas() {
    var { caracteristicas_array } = this.state;
    let exportData = <div>No data available</div>
    if (caracteristicas_array) {
      exportData =
        <div>
          <h5>Caracteristicas</h5>
          <div data-uk-grid className="uk-grid-small">
            <div className="uk-width-1-2@s">
              {caracteristicas_array && caracteristicas_array.map((route, key) =>
                key < (caracteristicas_array.length / 2) &&
                <div key={key} className="md-checkbox">
                  <input checked={this.state.caracteristicas_array[key].checked} id={`check_caracteristicas_${route.CaracteristicaId}`} type="checkbox" onChange={this.addServiceHandler(route.CaracteristicaId, this.state.caracteristicas_array[key].propertyFeature)} />
                  <label htmlFor={`check_caracteristicas_${route.CaracteristicaId}`}>{route.Descripcion}</label>
                </div>
              )}
            </div>
            <div className="uk-width-1-2@s">
              {caracteristicas_array && caracteristicas_array.map((route, key) =>
                key >= (caracteristicas_array.length / 2) &&
                <div key={key} className="md-checkbox">
                  <input checked={this.state.caracteristicas_array[key].checked} id={`check_caracteristicas_${route.CaracteristicaId}`} type="checkbox" onChange={this.addServiceHandler(route.CaracteristicaId, this.state.caracteristicas_array[key].propertyFeature)} />
                  <label htmlFor={`check_caracteristicas_${route.CaracteristicaId}`}>{route.Descripcion}</label>
                </div>
              )}
            </div>
          </div>
        </div>
    };
    return exportData;
  }
  _renderServicios() {
    const { servicios_array, propertyServices } = this.state;
    console.log('_renderServicios');
    console.log(propertyServices);
    let exportData = <div>No data available</div>
    if (servicios_array) {
      exportData =
        <div>
          <h5>Servicios</h5>
          <div data-uk-grid className="uk-grid-small">
            <div className="uk-width-1-2@s">
              {servicios_array.map((route, key) =>
                key < (servicios_array.length / 2) &&
                <div key={key} className="md-checkbox">
                  <input checked={this.state.servicios_array[key].checked} id={`check_servicios_${route.CaracteristicaId}`} type="checkbox" onChange={this.addServiceHandler(route.CaracteristicaId, this.state.servicios_array[key].propertyFeature)} />
                  <label htmlFor={`check_servicios_${route.CaracteristicaId}`}>{route.Descripcion}</label><br />
                  {(route.RequiereValor === '1' && this.state.servicios_array[key].checked) &&
                    <input type="number" maxLength='4' size="4" value={this.state.servicios_array[key].value} onChange={this.handleInputCaracteristicaValue(key)} />
                  }
                </div>
              )}
            </div>
            <div className="uk-width-1-2@s">
              {servicios_array.map((route, key) =>
                key >= (servicios_array.length / 2) &&
                <div key={key} className="md-checkbox">
                  <input checked={this.state.servicios_array[key].checked} id={`check_servicios_${route.CaracteristicaId}`} type="checkbox" onChange={this.addServiceHandler(route.CaracteristicaId, this.state.servicios_array[key].propertyFeature)} />
                  <label htmlFor={`check_servicios_${route.CaracteristicaId}`}>{route.Descripcion}</label>
                  {(route.RequiereValor === '1' && this.state.servicios_array[key].checked) &&
                    <input type="number" maxLength='4' size="4" value={this.state.servicios_array[key].value} onChange={this.handleInputCaracteristicaValue(key)} />
                  }
                </div>
              )}
            </div>
          </div>
        </div>
    }
    return exportData;
  }
  _fetchPropertyFeatures({ propertyId }) {
    fetch(`${AppSettings.connectionString}/admin/API/script_getCaracteristicasByInmuebleId.php?InmuebleId=${propertyId}`)
      .then(res => res.json())
      .then(data => {
        console.log('_fetchPropertyFeatures');
        this.setState({ propertyFeatures: data, loading: false })
        this.setCaracteristicasList();
      })
  }
  _fetchPropertyServices({ propertyId }) {
    fetch(`${AppSettings.connectionString}/admin/API/script_getServiciosByInmuebleId.php?InmuebleId=${propertyId}`)
      .then(res => res.json())
      .then(data => {
        console.log('_fetchPropertyServices');
        console.log(data);
        this.setState({ propertyServices: data, loading: false });
        this.setServiciosList();
      })
  }
  _fetchCaracteristicas() {
    fetch(`${AppSettings.connectionString}/admin/API/script_getCaracteristicas.php`)
      .then(res => res.json())
      .then(data => {
        console.log('_fetchCaracteristicas');
        this._fetchPropertyFeatures({ propertyId: this.state.propertyId });
        this.setState({ caracteristicas_array: data, loading: false })
      })
      .catch(error => console.error('Error:', error));
  }
  _fetchServicios() {
    fetch(`${AppSettings.connectionString}/admin/API/script_getServicios.php`)
      .then(res => res.json())
      .then(data => {
        console.log('_fetchServicios');
        console.log(data);
        this._fetchPropertyServices({ propertyId: this.state.propertyId });
        this.setState({ servicios_array: data, loading: false })
      })
      .catch(error => console.error('Error:', error));
  }
  saveHandler = (e) => {
    var formData = new FormData();
    formData.append('ArraySelectedServicios', JSON.stringify(this.state.selected_services));
    formData.append('ArrayUnselectedServicios', JSON.stringify(this.state.unselected_services));
    formData.append('ArrayUpdatedServicios', JSON.stringify(this.state.updated_services));
    formData.append('propertyId', this.state.propertyId);
    fetch(`${AppSettings.connectionString}/admin/API/script_UpdateCaracteristicas.php`, {
      method: 'POST',
      body: formData
    })
      .then(res => res.text())
      .then(data => {
        console.log(data);
        if (data.RowsAffected) {
          UIkit.notification({
            message: 'New event created',
            status: 'success',
            pos: 'top-right'
          });
        }
        this.setState({ nextStep: true });
      }).catch(function (error) {
        console.log(error);
        UIkit.notification({
          message: 'Error al guardar. Intentelo de nuevo.',
          status: 'danger',
          pos: 'top-right'
        });
      });
  }
  handleInputCaracteristicaValue = (index) => (e) => {
    var { servicios_array, updated_services } = this.state;
    servicios_array[index].value = e.target.value;
    console.log('updated_services');
    var newCaracteristica = new Object();
    newCaracteristica.CaracteristicaId = servicios_array[index].CaracteristicaId;
    newCaracteristica.Value = e.target.value;
    var newCaracteristicaPosInArray;
    for (var i = 0; i < updated_services.length; i++) {
      if (updated_services[i].CaracteristicaId === newCaracteristica.CaracteristicaId) {
        newCaracteristicaPosInArray = i;
      }
    }
    if (newCaracteristicaPosInArray == undefined) {
      updated_services.push(newCaracteristica);
    } else {
      updated_services[newCaracteristicaPosInArray].Value = e.target.value;
    }
    this.setState({ servicios_array: servicios_array, updated_services: updated_services });
  }
  addServiceHandler = (Id, IsAPropertyFeature) => (e) => {
    console.log('pertenece a la propiedad' + IsAPropertyFeature);
    var array_selected_services = this.state.selected_services;
    var array_unselected_services = this.state.unselected_services;
    var { caracteristicas_array, servicios_array } = this.state;
    if (e.target.checked) {
      caracteristicas_array.forEach(element => {
        if (Id === element.CaracteristicaId) {
          element.checked = true;
        }
      });
      servicios_array.forEach(element => {
        if (Id === element.CaracteristicaId) {
          element.checked = true;
        }
      });
      if (IsAPropertyFeature) {
        for (var i = 0; i < array_unselected_services.length; i++) {
          if (array_unselected_services[i] === Id) {
            array_unselected_services.splice(i, 1);
            i--;
          }
        }
      } else {
        var newCaracteristica = new Object();
        newCaracteristica.CaracteristicaId = Id;
        newCaracteristica.Value = 0;
        array_selected_services.push(newCaracteristica);
      }

    } else {
      caracteristicas_array.forEach(element => {
        if (Id === element.CaracteristicaId) {
          element.checked = false;
        }
      });
      servicios_array.forEach(element => {
        if (Id === element.CaracteristicaId) {
          element.checked = false;
        }
      });
      if (IsAPropertyFeature) {
        array_unselected_services.push(Id);
      } else {
        for (var i = 0; i < array_selected_services.length; i++) {
          if (array_selected_services[i].CaracteristicaId === Id) {
            array_selected_services.splice(i, 1);
            i--;
          }
        }
      }
    }
    console.log(array_selected_services);
    this.setState({ unselected_services: array_unselected_services, selected_services: array_selected_services, caracteristicas_array: caracteristicas_array, servicios_array: servicios_array });
    //console.log(newArray);
  }
  render() {
    const Id = 1;
    const acomunes_array = [
      {
        id: "1",
        name: "Sala de entretenimiento"
      }, {
        id: "2",
        name: "Sauna"
      }, {
        id: "3",
        name: "Bodega"
      }
    ];
    var { propertyId } = this.state;
    if (this.state.nextStep) {
      return <Redirect push to={`/admin/nuevoaviso/paso3/${propertyId}`} />;
    }
    return (
      <div>
        <ul className="stepper stepper-horizontal">
          <li className="completed">
            <Link to={`../paso1/${propertyId}`}>
              <span className="circle"><i className="material-icons">check</i></span>
              <span className="label">Datos Principales</span>
            </Link>
          </li>
          <li className="active">
            <a>
              <span className="circle">2</span>
              <span className="label">Caracteristicas</span>
            </a>
          </li>
          <li>
            <a>
              <span className="circle">3</span>
              <span className="label">Multimedia</span>
            </a>
          </li>
        </ul>
        <div data-uk-grid >
          <div className="uk-width-1-1">
            {this._renderCaracteristicas()}
            <hr />
            {this._renderServicios()}
            <hr />
            {/* <div className="uk-width-1-2">
              <h5>Areas comunes</h5>
              <div data-uk-grid className="uk-grid-small">
                <div className="uk-width-1-1@m">
                  {acomunes_array.map((route, key) =>
                    <div key={key} className="md-checkbox">
                      <input id={`check_acomunes_${route.id}`} type="checkbox" value={route.id} />
                      <label htmlFor={`check_acomunes_${route.id}`}>{route.name}</label>
                    </div>
                  )}
                </div>
              </div>
            </div> */}
            <div className='uk-padding-small uk-align-right'>
              <button onClick={this.saveHandler} className="md-btn md-btn-secondary" >CONTINUAR ></button>
            </div>
          </div>
        </div>
      </div >
    )
  }
}
export default Step2;