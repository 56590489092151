import React, { Component } from 'react';

import Main from '../MainContent/MainContent';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import { Redirect } from "react-router-dom";

class Panel extends Component {
  state = {
    isLogged: true
  }
  render() {
    if (this.state.isLogged) {
      return (
        <div>
          <Header />
          <Sidebar />
          <Main />
        </div>
      )
    }
    return <Redirect push to="/login" />;
  }
}
export default Panel;