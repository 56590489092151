import React, { Component } from 'react';
import MDUIkit from 'md-uikit';
import { Link } from "react-router-dom";

// Components
import FrontHeader from '../Header/Header';
import PropertyInfo from '../../../components/property/info.component';
import FrontFooter from '../Footer/Footer';

// Images
import portada1 from '../../../assets/img/portada_1.jpg';

import AppSettings from '../../../app_settings.js';
import PageHeader from '../Components/PageHeader';

class Propiedades extends Component {
  state = {
    properties_array: [],
    loading: true,
    distritos_array: [],
    current_page: 1
  }
  componentDidMount() {
    MDUIkit.components();
    this._fetchDistritos();
    this._fetchInmuebles();
  }
  _fetchInmuebles() {
    var formData = new FormData();
    formData.append('EstadoId', 1);
    fetch(`${AppSettings.connectionString}/admin/API/script_getInmueblesByEstado.php`, {
      method: 'POST',
      body: formData
    })
      .then(res => res.json())
      .then(data => {
        //console.log(data);
        this.setState({ properties_array: data, loading: false })
      }).catch(() => {
        console.log("Sin conexion");
      });
  }
  _fetchDistritos() {
    fetch(`${AppSettings.connectionString}/admin/API/script_getDistritos.php`)
      .then(res => res.json())
      .then(data => {
        this.setState({ distritos_array: data, loading: false });
      })
  }
  componentDidUpdate() {
    const { type, page } = this.props.match.params;
    switch (type) {
      case '0':
        document.querySelector('#radio_filter_todos').checked = true;
        break;
      case '1':
        document.querySelector('#radio_filter_comprar').checked = true;
        break;
      case '2':
        document.querySelector('#radio_filter_alquilar').checked = true;
        break;
      case '3':
        document.querySelector('#radio_filter_traspaso').checked = true;
        break;
      case '4':
        document.querySelector('#radio_filter_temporal').checked = true;
        break;
      default:
        console.log('default');
    }
  }
  _renderProperties() {
    const AdsPerPage = 5;
    const { properties_array } = this.state;
    const { type, district, page } = this.props.match.params;
    let control_top = parseInt(AdsPerPage) * parseInt(page);
    let control_bottom = (parseInt(page) - 1) * AdsPerPage;
    let exportData = <div>No hay informacion para mostrar </div>
    if (properties_array.length) {
      switch (type) {
        case '0':
          exportData = properties_array.map((propiedad, key) => (
            (control_bottom < (key + 1) && (key + 1) <= control_top) &&
            <PropertyInfo propiedadId={propiedad.InmuebleId} />
          )
          );
          break;
        case '1':
          {
            exportData = properties_array.map((propiedad, key) => (
              (control_bottom < (key + 1) && (key + 1) <= control_top) && (
                (propiedad.VentaActiva != '0' &&
                  <PropertyInfo propiedadId={propiedad.InmuebleId} />
                ))
            ));
            break;
          }
        case '2':
          exportData = properties_array.map((propiedad, key) => (
            (control_bottom < (key + 1) && (key + 1) <= control_top) && (
              (propiedad.AlquilerActivo != '0' &&
                <PropertyInfo propiedadId={propiedad.InmuebleId} />
              ))
          ));
          break;
        default:
          break;
      }

    }
    return exportData;
  }
  _renderDistritos() {
    var { distritos_array } = this.state;
    let exportData = <div>No data available</div>
    if (distritos_array) {
      exportData =
        <div className="md-input-wrapper md-input-filled md-input-wrapper-outlined">
          <label>Distrito</label>
          <select id="select_distrito" className="md-input " onChange={this.onChangeFilter}>
            <option value="0">Todos</option>
            {distritos_array && distritos_array.map((route, key) =>
              <option value={route.DistritoId}>{route.Descripcion}</option>
            )}
          </select>
          <span className="md-input-bar "></span>
        </div>
    };
    return exportData;
  }
  onChangeFilter = (e) => {
    const select_distrito = document.querySelector('#select_distrito').value;
    const radio_filter_todos = document.querySelector('#radio_filter_todos').checked;
    const radio_filter_comprar = document.querySelector('#radio_filter_comprar').checked;
    const radio_filter_alquilar = document.querySelector('#radio_filter_alquilar').checked;
    if (radio_filter_todos) this.props.history.push('/propiedades/0/' + select_distrito + '/1');
    if (radio_filter_comprar) this.props.history.push('/propiedades/1/' + select_distrito + '/1');
    if (radio_filter_alquilar) this.props.history.push('/propiedades/2/' + select_distrito + '/1');
  }
  render() {
    const { page } = this.props.match.params;
    let next_page = parseInt(page) + 1;
    let before_page = parseInt(page) - 1;
    return (
      <div>
        <FrontHeader />
        <PageHeader title="Propiedades" />
        <div className='uk-container'>
          <br />
          <div data-uk-grid>
            <div className="uk-width-1-3@m">
              <div className="uk-card md-bg-white">
                <div className="uk-card-body">
                  <h4>Filtros</h4>
                  <h5 className="uk-margin-remove-top">Distrito</h5>
                  {this._renderDistritos()}
                  <h5>Tipo de Operacion</h5>
                  <div>
                    <div className="md-radio">
                      <input id="radio_filter_todos" type="radio" name="radios_type" defaultChecked onChange={this.onChangeFilter} />
                      <label htmlFor="radio_filter_todos">Todos</label>
                    </div>
                    <div className="md-radio">
                      <input id="radio_filter_comprar" type="radio" name="radios_type" onChange={this.onChangeFilter} />
                      <label htmlFor="radio_filter_comprar">Comprar</label>
                    </div>
                    <div className="md-radio">
                      <input id="radio_filter_alquilar" type="radio" name="radios_type" onChange={this.onChangeFilter} />
                      <label htmlFor="radio_filter_alquilar">Alquilar</label>
                    </div>
                    {/* <div className="md-radio">
                      <input id="radio_filter_traspaso" type="radio" name="radios_type" onChange={this.onChangeFilter} />
                      <label for="radio_filter_traspaso">Traspaso</label>
                    </div>
                    <div className="md-radio">
                      <input id="radio_filter_temporal" type="radio" name="radios_type" onChange={this.onChangeFilter} />
                      <label for="radio_filter_temporal">Temporal</label>
                    </div> */}
                  </div>
                  {/* <h5>Precio</h5>
                  <div className="uk-child-width-1-2 uk-grid-small" data-uk-grid>
                    <div>
                      <div className="md-input-wrapper md-input-dense">
                        <label>Desde:</label>
                        <input type="text" className="md-input" />
                        <span className="md-input-bar"></span>
                      </div>
                    </div>
                    <div>
                      <div className="md-input-wrapper md-input-dense">
                        <label>Hasta:</label>
                        <input type="text" className="md-input" />
                        <span className="md-input-bar"></span>
                      </div>
                    </div>
                  </div>
                  <h5>Mantenimiento</h5>
                  <div className="uk-child-width-1-2 uk-grid-small" data-uk-grid>
                    <div>
                      <div className="md-input-wrapper md-input-dense">
                        <label>Desde:</label>
                        <input type="text" className="md-input" />
                        <span className="md-input-bar"></span>
                      </div>
                    </div>
                    <div>
                      <div className="md-input-wrapper md-input-dense">
                        <label>Hasta:</label>
                        <input type="text" className="md-input" />
                        <span className="md-input-bar"></span>
                      </div>
                    </div>
                  </div>
                  <h5>Tipo de Inmueble</h5>
                  <div className="md-input-wrapper">
                    <label>Seleccionar distrito</label>
                    <select className="md-input">
                      <option disabled selected hidden></option>
                      <option value="a2">First Option</option>
                      <option value="b2">Second Option</option>
                      <option value="c2">Third Option</option>
                      <option value="d2">Fourth Option</option>
                    </select>
                    <span className="md-input-bar "></span>
                  </div>
                  <h5>Dormitorios</h5>
                  <div className="md-input-wrapper">
                    <label>Seleccionar distrito</label>
                    <select className="md-input">
                      <option disabled selected hidden></option>
                      <option value="a2">First Option</option>
                      <option value="b2">Second Option</option>
                      <option value="c2">Third Option</option>
                      <option value="d2">Fourth Option</option>
                    </select>
                    <span className="md-input-bar "></span>
                  </div>
                  <h5>Baños</h5>
                  <div className="md-input-wrapper">
                    <label>Seleccionar distrito</label>
                    <select className="md-input">
                      <option disabled selected hidden></option>
                      <option value="a2">First Option</option>
                      <option value="b2">Second Option</option>
                      <option value="c2">Third Option</option>
                      <option value="d2">Fourth Option</option>
                    </select>
                    <span className="md-input-bar "></span>
                  </div>
                  <h5>Estacionamientos</h5>
                  <div className="md-input-wrapper">
                    <label>Seleccionar Estacionamientos</label>
                    <select className="md-input">
                      <option disabled selected hidden></option>
                      <option value="a2">First Option</option>
                      <option value="b2">Second Option</option>
                      <option value="c2">Third Option</option>
                      <option value="d2">Fourth Option</option>
                    </select>
                    <span className="md-input-bar "></span>
                  </div>
                  <h5>Antigüedad</h5>
                  <div className="md-input-wrapper">
                    <label>Seleccionar Antigüedad</label>
                    <select className="md-input">
                      <option disabled selected hidden></option>
                      <option value="a2">First Option</option>
                      <option value="b2">Second Option</option>
                      <option value="c2">Third Option</option>
                      <option value="d2">Fourth Option</option>
                    </select>
                    <span className="md-input-bar "></span>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="uk-width-2-3@m">
              <div data-uk-grid className="uk-grid-small">

                <p align='right'>{`Pagina: ${page} de ${(Math.ceil(this.state.properties_array.length / 5))}`}</p>
                {this.state.loading
                  ? <div className="uk-width-1-1 "><h3 align="center" className='uk-text-center'><div data-uk-spinner></div> &nbsp; Cargando...</h3></div>
                  : this._renderProperties()
                }

                <div className="uk-width-1-1 uk-text-center">
                  {page > 1 && <Link to={`${before_page}`} className='md-btn md-btn-primary md-color-white'><i className="md-color-white material-icons">keyboard_arrow_left
</i> Anterior</Link>}

                  {page < Math.ceil(this.state.properties_array.length / 5) && <Link to={`${next_page}`} onClick={window.scrollTo(0, 0)} className='md-btn md-btn-primary md-color-white'>Siguiente <i className="material-icons md-color-white">
                    keyboard_arrow_right
</i></Link>}
                </div>
              </div>

            </div>
          </div>
        </div>
        <br />
        <FrontFooter />
      </div>
    )
  }
}
export default Propiedades;