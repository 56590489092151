import { PropertiesActionTypes } from './properties.types';
const INITIAL_STATE = {
    currentProperties : null
}
const propertiesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PropertiesActionTypes.SET_CURRENT_PROPERTIES:
            return {
                ...state,
                currentProperties : action.payload
            };
        default:
            return state;
    }
}
export default propertiesReducer;