import React, { Component } from 'react';
import MDUIkit from 'md-uikit';
import Step1 from './Step1.js';
import Step2 from './Step2.js';
import Step3 from './Step3.js';
import { Route, Switch } from "react-router-dom";
class NuevoAviso extends Component {
  state = {
    selectedFile: null,
    images_array: []
  }

  componentDidMount() {
    MDUIkit.components();
  }

  render() {
    return (
      <div>
        <h3>Publicar Aviso</h3>
        <div className='uk-card md-bg-white'>
          <div className='uk-card-body'>
            <Route path='/admin/nuevoaviso/paso1/:propiedadId' component={Step1} />
            <Route path='/admin/nuevoaviso/paso2/:propiedadId' component={Step2} />
            <Route path='/admin/nuevoaviso/paso3/:propiedadId' component={Step3} />
          </div>
        </div>
      </div>
    )
  }
}
export default NuevoAviso;