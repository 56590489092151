import React, { Component } from 'react';
/* import UIkit from 'uikit'; */
import { HashRouter, NavLink, Route } from "react-router-dom";
import MDUIkit from 'md-uikit';

import Routes from '../routes.js';

import 'md-uikit/dist/css/md-uikit.css';

import Logo from "../../../assets/img/main_logo_alt.png";

class SideBar extends Component {
  componentDidMount() {
    MDUIkit.drawer();
  }
  render() {
    var Style = {
      'backgroundImage': "url('assets/img/main_logo_alt.png')"
    }
    return (
      <HashRouter>
        <aside id="md-drawer" uk-offcanvas="overlay: true">
          <div className="uk-offcanvas-bar">
            <div className="md-drawer_header"  >
              <img src={Logo} />
              {/* <div className="uk-padding-small uk-grid-small uk-flex-middle uk-grid" uk-grid="">
                <div className="uk-width-auto uk-first-column">
                  <img className="uk-border-circle" width="40" height="40" src="https://getuikit.com/docs/images/avatar.jpg" />
                </div>
                <div className="uk-width-expand">
                  <h3 className="md-drawer_header_title md-color-white uk-margin-remove-bottom">Email</h3>
                  <p className="md-drawer_header_subtitle uk-margin-remove md-color-white">email@email.com</p>
                </div>
              </div> */}
            </div>
            <div className="md-drawer_content" >
              <ul uk-nav="multiple: true">
                {Routes.map((route, key) =>
                  <Route
                    key={key}
                    path={route.path}
                    children={({ match }) => (
                      <li className={route.path ? (match && "uk-active") : (route.submenu && 'uk-parent')}>
                        <NavLink to={route.path}>
                          <span className="menu_icon"><i className="material-icons">{route.icon}</i></span>
                          <span className="menu_title">{route.name}</span>
                        </NavLink>
                        {route.submenu &&
                          <ul className="uk-nav-sub">
                            {route.submenu.map((inner_route, key) =>
                              <Route
                                path={inner_route.path}
                                children={({ match }) => (
                                  <li className={match ? "uk-active" : ""}>
                                    <NavLink to={inner_route.path}>{inner_route.name}</NavLink>
                                  </li>
                                )}
                              />
                            )}
                          </ul>
                        }
                      </li>
                    )}
                  />
                )}
              </ul>
            </div>
          </div>
        </aside>
      </HashRouter>
    )
  }
}

export default SideBar;