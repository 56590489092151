import React, { Component } from 'react';
import MDUIkit from 'md-uikit';
import { Redirect } from "react-router-dom";
import UIkit from 'uikit';
import AppSettings from '../../../app_settings';
class Step1 extends Component {
  state = {
    images_array: [],
    propertyInfo: [],
    distritos_array: [],
    nextStep: false,
    isLoading: true
  }

  componentDidMount() {
    const { propiedadId } = this.props.match.params;
    MDUIkit.components();
    this._fetchPropertyDetail({ propertyId: propiedadId });
    this._fetchDistritos();
  }
  _fetchPropertyDetail({ propertyId }) {
    fetch(`${AppSettings.connectionString}/admin/API/script_getInmuebleById.php?InmuebleId=${propertyId}`)
      .then(res => res.json())
      .then(data => {
        this.setState({ propertyInfo: data, isLoading: false });
      })
  }
  _fetchDistritos() {
    fetch(`${AppSettings.connectionString}/admin/API/script_getDistritos.php`)
      .then(res => res.json())
      .then(data => {
        this.setState({ distritos_array: data });
      })
  }
  _renderDistritos() {
    var { distritos_array } = this.state;
    let exportData = <div>No data available</div>
    if (distritos_array) {
      exportData =
        <div className="md-input-wrapper md-input-filled md-input-wrapper-outlined">
          <label>Distrito</label>
          <select id="select_distrito" className="md-input " value={this.state.propertyInfo.DistritoId} onChange={this.handleInputText}>
            <option disabled selected hidden></option>
            {distritos_array && distritos_array.map((route, key) =>
              <option value={route.DistritoId}>{route.Descripcion}</option>
            )}
          </select>
          <span className="md-input-bar "></span>
        </div>
    };
    return exportData;
  }
  handleInputText = (e) => {
    var { propertyInfo } = this.state;
    switch (e.target.id) {
      case 'input_title': {
        propertyInfo.Titulo = e.target.value;
        break;
      }
      case 'input_description': {
        propertyInfo.Descripcion = e.target.value;
        break;
      }
      case 'select_type': {
        propertyInfo.TipoInmuebleId = e.target.value;
        break;
      }
      case 'input_years': {
        propertyInfo.Antiguedad = e.target.value;
        break;
      }
      case 'input_bedrooms': {
        propertyInfo.Dormitorios = e.target.value;
        break;
      }
      case 'input_parking': {
        propertyInfo.Estacionamientos = e.target.value;
        break;
      }
      case 'input_fullbathrooms': {
        propertyInfo.BanosCompletos = e.target.value;
        break;
      }
      case 'input_halfbathrooms': {
        propertyInfo.BanosMedios = e.target.value;
        break;
      }
      case 'input_m2_contruidos': {
        propertyInfo.MetrosConstruidos = e.target.value;
        break;
      }
      case 'input_m2_total': {
        propertyInfo.MetrosTotales = e.target.value;
        break;
      }
      case 'select_distrito': {
        propertyInfo.DistritoId = e.target.value;
        break;
      }
      case 'input_price_sale_PEN': {
        propertyInfo.PrecioVenta = e.target.value;
        break;
      }
      case 'input_price_rent_PEN': {
        propertyInfo.PrecioAlquiler = e.target.value;
        break;
      }
      case 'checkbox_tosale': {
        propertyInfo.VentaActiva = e.target.checked;
        break;
      }
      case 'checkbox_torent': {
        propertyInfo.AlquilerActivo = e.target.checked;
        break;
      }
      case 'input_price_sale_USD': {
        propertyInfo.PrecioVentaUSD = e.target.value;
        break;
      }
      case 'input_price_rent_USD': {
        propertyInfo.PrecioAlquilerUSD = e.target.value;
        break;
      }
      case 'input_mantenimiento': {
        propertyInfo.Mantenimiento = e.target.value;
        break;
      }
      default: {
        console.log('sw default');
      }
    }
    this.setState({ propertyInfo: propertyInfo, loading: false });
  }
  onClickSave = (e) => {
    const input_title = document.querySelector('#input_title').value;
    const input_description = document.querySelector('#input_description').value;
    const select_type = document.querySelector('#select_type').value;
    const input_years = document.querySelector('#input_years').value;
    const input_bedrooms = document.querySelector('#input_bedrooms').value;
    const input_parking = document.querySelector('#input_parking').value;
    const input_fullbathrooms = document.querySelector('#input_fullbathrooms').value;
    const input_halfbathrooms = document.querySelector('#input_halfbathrooms').value;
    const input_price_sale_PEN = document.querySelector('#input_price_sale_PEN').value;
    const input_price_sale_USD = document.querySelector('#input_price_sale_USD').value;
    const checkbox_tosale = document.querySelector('#checkbox_tosale').checked;
    const checkbox_torent = document.querySelector('#checkbox_torent').checked;
    const input_price_rent_PEN = document.querySelector('#input_price_rent_PEN').value;
    const input_price_rent_USD = document.querySelector('#input_price_rent_USD').value;
    const input_mantenimiento = document.querySelector('#input_mantenimiento').value;
    const input_m2_contruidos = document.querySelector('#input_m2_contruidos').value;
    const input_m2_total = document.querySelector('#input_m2_total').value;
    const select_distrito = document.querySelector('#select_distrito').value;

    //const btn_enviar = document.querySelector('#btn_enviar');
    var formData = new FormData();
    formData.append('Titulo', input_title);
    formData.append('Descripcion', input_description);
    formData.append('TipoInmuebleId', select_type);
    formData.append('Antiguedad', input_years);
    formData.append('Dormitorios', input_bedrooms);
    formData.append('Estacionamientos', input_parking);
    formData.append('BanosCompletos', input_fullbathrooms);
    formData.append('BanosMedios', input_halfbathrooms);
    formData.append('MetrosConstruidos', input_m2_contruidos);
    formData.append('MetrosTotales', input_m2_total);
    formData.append('DistritoId', select_distrito);
    formData.append('EstadoId', 1);
    formData.append('PrecioVenta', input_price_sale_PEN);
    formData.append('PrecioAlquiler', input_price_rent_PEN);
    formData.append('VentaActiva', checkbox_tosale);
    formData.append('AlquilerActivo', checkbox_torent);
    formData.append('PrecioVentaUSD', input_price_sale_USD);
    formData.append('PrecioAlquilerUSD', input_price_rent_USD);
    formData.append('Mantenimiento', input_mantenimiento);
    const url = `${AppSettings.connectionString}/admin/API/script_InsertInmueble.php`;
    e.target.disable = true;
    e.target.classList.remove('md-btn-secondary');
    e.target.classList.add('disabled');
    if (input_title && select_type) {
      fetch(url, {
        method: 'POST',
        body: formData
      })
        .then(res => res.json())
        .then(data => {

          console.log(data);
          if (data.RowsAffected) {
            UIkit.notification({
              message: 'New event created',
              status: 'success',
              pos: 'top-right'
            });
          }
          var propertyInfo = [];
          propertyInfo.InmuebleId = data.InsertID;
          console.log(propertyInfo);
          this.setState({ propertyInfo: propertyInfo, nextStep: true });
        }).catch(function (error) {
          console.log(error);
          UIkit.notification({
            message: 'Error al guardar. Intentelo de nuevo.',
            status: 'danger',
            pos: 'top-right'
          });
        });
    } else {
      alert('Completa los espacios marcados en rojo');
    }
  }
  onClickUpdate = (e) => {
    var { propertyInfo } = this.state;
    //const btn_enviar = document.querySelector('#btn_enviar');
    var formData = new FormData();
    formData.append('InmuebleId', propertyInfo.InmuebleId);
    formData.append('Titulo', propertyInfo.Titulo);
    formData.append('Descripcion', propertyInfo.Descripcion);
    formData.append('TipoInmuebleId', propertyInfo.TipoInmuebleId);
    formData.append('Antiguedad', propertyInfo.Antiguedad);
    formData.append('Dormitorios', propertyInfo.Dormitorios);
    formData.append('Estacionamientos', propertyInfo.Estacionamientos);
    formData.append('BanosCompletos', propertyInfo.BanosCompletos);
    formData.append('BanosMedios', propertyInfo.BanosMedios);
    formData.append('MetrosConstruidos', propertyInfo.MetrosConstruidos);
    formData.append('MetrosTotales', propertyInfo.MetrosTotales);
    formData.append('DistritoId', propertyInfo.DistritoId);
    formData.append('EstadoId', propertyInfo.EstadoId);
    formData.append('PrecioVenta', propertyInfo.PrecioVenta);
    formData.append('PrecioAlquiler', propertyInfo.PrecioAlquiler);
    formData.append('VentaActiva', propertyInfo.VentaActiva);
    formData.append('AlquilerActivo', propertyInfo.AlquilerActivo);
    formData.append('PrecioVentaUSD', propertyInfo.PrecioVentaUSD);
    formData.append('PrecioAlquilerUSD', propertyInfo.PrecioAlquilerUSD);
    formData.append('Mantenimiento', propertyInfo.Mantenimiento);
    console.log('alquiler activo' + propertyInfo.AlquilerActivo);
    const url = `${AppSettings.connectionString}/admin/API/script_UpdateInmueble.php`;
    if (propertyInfo.Titulo && propertyInfo.TipoInmuebleId) {
      fetch(url, {
        method: 'POST',
        body: formData
      })
        .then(res => res.json())
        .then(data => {
          if (data.RowsAffected) {
            UIkit.notification({
              message: 'Datos actualizados',
              status: 'success',
              pos: 'top-right'
            });
            this.setState({ nextStep: true });
          }
        }).catch(function (error) {
          console.log(error);
          UIkit.notification({
            message: 'Error al guardar. Intentelo de nuevo.',
            status: 'danger',
            pos: 'top-right'
          });
        });
    } else {
      alert('Completa los espacios marcados en rojo');
    }
  }
  render() {
    var { propertyInfo, isLoading } = this.state;
    if (this.state.nextStep) {
      return <Redirect push to={`/admin/nuevoaviso/paso2/${propertyInfo.InmuebleId}`} />;
    }
    return (
      <div>
        <ul class="stepper stepper-horizontal">
          <li class="active">
            <a>
              <span class="circle">1</span>
              <span class="label">Datos Principales</span>
            </a>
          </li>
          <li>
            <a>
              <span class="circle">2</span>
              <span class="label">Caracteristicas</span>
            </a>
          </li>
          <li>
            <a>
              <span class="circle">3</span>
              <span class="label">Multimedia</span>
            </a>
          </li>
        </ul>
        <div data-uk-grid>

          {!isLoading ?
            <div className="uk-width-1-1@m">
              <h5>Informacion General</h5>
              <div data-uk-grid className="uk-grid-small">
                <div className="uk-width-1-1@m">
                  <div className="md-input-wrapper md-input-filled md-input-wrapper-outlined">
                    <label>Titulo</label>
                    <input id='input_title' value={this.state.propertyInfo.Titulo} type="text" className="md-input" required onChange={this.handleInputText} />
                  </div>
                </div>
                <div className="uk-width-1-1@m">
                  <div className=" md-input-wrapper md-input-filled md-input-textarea">
                    <label>Descripcion</label>
                    <textarea id='input_description' className="md-input" value={this.state.propertyInfo.Descripcion} onChange={this.handleInputText}></textarea>
                  </div>
                </div>
                <div className="uk-width-1-2@m">
                  <div className="md-input-wrapper md-input-filled md-input-wrapper-outlined">
                    <label>Tipo de Inmueble</label>
                    <select id='select_type' className="md-input" required value={this.state.propertyInfo.TipoInmuebleId} onChange={this.handleInputText}>
                      <option disabled="" selected="" hidden></option>
                      <optgroup label="Residencial">
                        <option value="1">Departamento</option>
                        <option value="3">Habitación</option>
                        <option value="2">Casa</option>
                        <option value="7">Otros</option>
                        <option value="8">Terreno / Lote</option>
                        <option value="9">Casa de playa</option>
                        <option value="10">Casa de campo</option>
                      </optgroup>
                      <optgroup label="Comercial">
                        <option value="4">Local Comercial</option>
                        <option value="5">Local Industrial</option>
                        <option value="6">Oficina</option>
                      </optgroup>
                      <optgroup label="Industrial">
                        <option value="11">Terreno agrícola</option>
                      </optgroup>
                    </select>
                    <span className="md-input-bar "></span>
                  </div>
                </div>
                <div className="uk-width-1-2@m">
                  <div className="md-input-wrapper md-input-filled md-input-wrapper-outlined">
                    <label>Antiguedad</label>
                    <input id="input_years" type="number" className="md-input" onChange={this.handleInputText} value={this.state.propertyInfo.Antiguedad} />
                  </div>
                </div>
                <div className="uk-width-1-2@m">
                  <div className="md-input-wrapper md-input-filled md-input-wrapper-outlined">
                    <label>Dormitorios</label>
                    <input id="input_bedrooms" type="number" className="md-input" onChange={this.handleInputText} value={this.state.propertyInfo.Dormitorios} />
                  </div>
                </div>
                <div className="uk-width-1-2@m">
                  <div className="md-input-wrapper md-input-filled md-input-wrapper-outlined">
                    <label>Estacionamientos</label>
                    <input id="input_parking" type="number" className="md-input" onChange={this.handleInputText} value={this.state.propertyInfo.Estacionamientos} />
                  </div>
                </div>
                <div className="uk-width-1-2@m">
                  <div className="md-input-wrapper md-input-filled md-input-wrapper-outlined">
                    <label>Baños completos</label>
                    <input id="input_fullbathrooms" type="text" onChange={this.handleInputText} className="md-input" value={this.state.propertyInfo.BanosCompletos} />
                  </div>
                </div>
                <div className="uk-width-1-2@m">
                  <div className="md-input-wrapper md-input-filled md-input-wrapper-outlined">
                    <label>Medios Baños</label>
                    <input id="input_halfbathrooms" type="text" onChange={this.handleInputText} className="md-input" value={this.state.propertyInfo.BanosMedios} />
                  </div>
                </div>
              </div>
              <h5>Precio</h5>
              <div data-uk-grid >
                <div className="uk-width-1-4@m">
                  <div className="uk-card uk-card-body">
                    <div className="md-checkbox">
                      <input id="checkbox_tosale" type="checkbox" onChange={this.handleInputText} checked={this.state.propertyInfo.VentaActiva} />
                      <label htmlFor="checkbox_tosale">Anunciar para vender</label>
                    </div>
                    <div className="md-input-wrapper md-input-filled md-input-wrapper-outlined">
                      <label>S/.</label>
                      <input id="input_price_sale_PEN" type="number" className="md-input" onChange={this.handleInputText} value={this.state.propertyInfo.PrecioVenta} />
                    </div>
                    <div className="md-input-wrapper md-input-filled md-input-wrapper-outlined">
                      <label>USD</label>
                      <input id="input_price_sale_USD" type="number" className="md-input" onChange={this.handleInputText} value={this.state.propertyInfo.PrecioVentaUSD} />
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-4@m">
                  <div className="uk-card uk-card-body">
                    <div className="md-checkbox">
                      <input id="checkbox_torent" type="checkbox" onChange={this.handleInputText} checked={this.state.propertyInfo.AlquilerActivo} />
                      <label htmlFor="checkbox_torent">Anunciar para alquilar</label>
                    </div>
                    <div className="md-input-wrapper md-input-filled md-input-wrapper-outlined">
                      <label>S/.</label>
                      <input id="input_price_rent_PEN" type="text" onChange={this.handleInputText} className="md-input" value={this.state.propertyInfo.PrecioAlquiler} />
                    </div>
                    <div className="md-input-wrapper md-input-filled md-input-wrapper-outlined">
                      <label>USD</label>
                      <input id="input_price_rent_USD" type="text" onChange={this.handleInputText} className="md-input" value={this.state.propertyInfo.PrecioAlquilerUSD} />
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-2@m">
                  <div className="md-input-wrapper md-input-filled md-input-wrapper-outlined">
                    <label>Mantenimiento</label>
                    <input id="input_mantenimiento" type="number" onChange={this.handleInputText} className="md-input" value={this.state.propertyInfo.Mantenimiento} />
                  </div>
                </div>
              </div>
              <h5>Dimensiones</h5>
              <div data-uk-grid className="uk-grid-small">
                <div className="uk-width-1-2@m">
                  <div className="md-input-wrapper md-input-filled md-input-wrapper-outlined">
                    <label>m2 construidos</label>
                    <input id="input_m2_contruidos" type="text" onChange={this.handleInputText} className="md-input" value={this.state.propertyInfo.MetrosConstruidos} />
                  </div>
                </div>
                <div className="uk-width-1-2@m">
                  <div className="md-input-wrapper md-input-filled md-input-wrapper-outlined">
                    <label>m2 totales</label>
                    <input id="input_m2_total" type="text" onChange={this.handleInputText} className="md-input" value={this.state.propertyInfo.MetrosTotales} />
                  </div>
                </div>
              </div>
              <h5>Ubicación</h5>
              <div data-uk-grid className="uk-grid-small">
                {/* <div className="uk-width-1-2@m">
                <div className="md-input-wrapper md-input-wrapper-outlined">
                  <label>Departamento</label>
                  <select className="md-input">
                    <option disabled selected hidden></option>

                    <option value="2">Departamento</option>
                    <option value="25">Habitación</option>
                  </select>
                  <span className="md-input-bar "></span>
                </div>
              </div>
              <div className="uk-width-1-2@m">
                <div className="md-input-wrapper md-input-wrapper-outlined">
                  <label>Provincia</label>
                  <select className="md-input">
                    <option disabled selected hidden></option>

                    <option value="2">Departamento</option>
                    <option value="25">Habitación</option>
                  </select>
                  <span className="md-input-bar "></span>
                </div>
              </div> */}
                <div className="uk-width-1-2@m">
                  {this._renderDistritos()}
                </div>
              </div>
              <div className='uk-padding-small uk-align-right'>
                {this.state.propertyInfo.InmuebleId ? (
                  <button onClick={this.onClickUpdate} className="md-btn md-btn-secondary">CONTINUAR</button>
                ) : (
                    <button onClick={this.onClickSave} className="md-btn md-btn-secondary">CONTINUAR ></button>
                  )}
              </div>

            </div>
            :
            <h3>Cargando...</h3>}
            
        </div>
      </div>
    )
  }
}
export default Step1;