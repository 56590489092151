

import Ads from "./Ads/Ads";
import Banners from "./ConfigPage/Banners";

const Routes = [
  {
    name: "Mis Avisos",
    path: "/admin/avisos",
    icon: 'home',
    component: Ads
  },
  {
    name: "Banners",
    path: "/admin/config",
    icon: 'settings',
    component: Banners
  }
];

export default Routes;
