import React, { Component } from 'react';
import { HashRouter, Route, Link, Switch } from "react-router-dom";
import UIkit from 'uikit';
import moment from 'moment';
//Components
import FrontHeader from '../Header/Header';
import FrontFooter from '../Footer/Footer';
import PropertyInfoCard from '../../../components/property/info-card.component';

import AppSettings from '../../../app_settings.js';

class Home extends Component {
  state = {
    properties_array: [],
    loading: true,
    bannersArray: []
  }
  componentDidMount() {
    this.fetchBanners();
    fetch(`${AppSettings.connectionString}/admin/API/script_getInmuebles.php`)
      .then(res => res.json())
      .then(data => {
        this.setState({ properties_array: data, loading: false })
      }).catch(() => {
        console.log("Sin conexion");
      });
  }
  _renderProperties() {
    const { properties_array } = this.state;
    let exportData = [];
    for (let index = 0; index < 10; index++) {
      if (index != 9) {
        exportData.push(
          <li key={index}>
            <PropertyInfoCard propiedadId={properties_array[index].InmuebleId} />
          </li>)
      } else {
        exportData.push(
          <li key={index}>
            <div className="uk-inline uk-card md-bg-white">
              <div className=" uk-card-body  uk-position-center md-color-grey-900">
                <Link to={`/propiedades/0/0/1`} className="md-btn md-btn-primary md-color-white md-btn-wave">VER MAS</Link>
              </div>
            </div>
          </li>)
      }
    }
    return exportData;
  }
  async fetchBanners() {
    console.log("fetchBanners");
    const url = `${AppSettings.connectionString}/admin/API/banner_read.php`;
    try {
      const response = await fetch(url, {
        method: 'GET',
      });
      if (response.status >= 400 && response.status < 500) {
        UIkit.notification('Information error.');
        throw "Error" + response.status;
      }
      if (response.status >= 500 && response.status < 600) {
        UIkit.notification('Error. Please try later.');
      }
      const res = await response.json();

      this.setState({ bannersArray: res })
      /* modal.hide(); */
      console.log(res);
    } catch (error) {
      /*  modal.hide(); */
      console.log(error);
    }
  }
  renderBanner(banner) {
    var content;
    switch (banner.visible) {
      case '1':
        content = (
          <li>
            <img src={`${AppSettings.connectionString}/admin/${banner.img}`} alt="" data-uk-cover />
          </li>
        )
        break;
      case '2':
        const hoy = moment();
        if (moment(banner.dateFrom) <= hoy && hoy <= moment(banner.dateTo)) {
          content = (
            <li>
              <img src={`${AppSettings.connectionString}/admin/${banner.img}`} alt="" data-uk-cover />
            </li>
          )
        }
        break;
      default:
        break;
    }
    return content;
  }
  render() {
    const { bannersArray } = this.state;
    return (
      <div>
        <FrontHeader />
        <div className="uk-position-relative uk-visible-toggle uk-light" tabIndex="-1" uk-slideshow="autoplay: true; max-height: 500;autoplay-interval: 8000;animation: pull">

          <ul className="uk-slideshow-items">
            {bannersArray.length > 0 && bannersArray.map(item => (
              this.renderBanner(item)
            ))
            }
          </ul>

          <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-previous data-uk-slideshow-item="previous"></a>
          <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-next data-uk-slideshow-item="next"></a>

        </div>
        <div className="uk-section">
          <div className="uk-container">
            <h3>Novedades</h3>
            <div className="uk-position-relative uk-visible-toggle uk-light uk-padding-small" tabIndex="-1" data-uk-slider="autoplay: true">
              <ul className="uk-slider-items uk-child-width-1-3@m uk-child-width-1-1 uk-child-width-1-2@s uk-grid uk-grid-match">
                {this.state.loading
                  ? <h3 align="center" className='md-color-grey-900'> &nbsp; Cargando...</h3>
                  : this._renderProperties()
                }
              </ul>
              <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-previous uk-slider-item="previous"></a>
              <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-next uk-slider-item="next"></a>
            </div>
          </div>
        </div>
        <FrontFooter />
      </div>
    )
  }
}
export default Home;