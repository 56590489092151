import React, { Component } from 'react';

// Components
import FrontHeader from '../Header/Header';
import FrontFooter from '../Footer/Footer';

// Images
import portada1 from '../../../assets/img/portada_1.jpg';
import arequipa from '../../../assets/img/arequipa.jpg';
import MoniFoto from '../../../assets/img/profile.jpg';
import PageHeader from '../Components/PageHeader';

class Nosotros extends Component {
  render() {
    return (
      <div>
        <FrontHeader />
        <PageHeader title="Nosotros" />
        <div className="uk-section uk-section-muted">
          <div className="uk-container">
            <div data-uk-grid>
              <div className="uk-width-1-2@m">
                <h3>Bienvenido</h3>
                <p align='justify'>
                  Tenemos como objetivo serviles de ayuda en la busqueda de las mejores soluciones posibles para rentabilizar sus decisiones. Nuestros consejos seran elaborados bajo estudios detallados dentro de los campos: financiero, economico; con la seguridad de ser mas eficaces consejeros de corretaje – bienes raices, queremos ofercerles nuestros servicios.
                </p>
                <ul>
                  <li>Tasaciones. Asesoramiento financiero.</li>
                  <li>Total confidencialidad.</li>
                  <li>Verificacion y asesoria.</li>
                  <li>Legal y documentaria.</li>
                  <li>Contamos con la red mas confiable del pais.</li>
                </ul>
              </div>
              <div className="uk-width-1-2@m">
                <img src={arequipa} alt=""/>
              </div>
            </div>

          </div>
        </div>
        <div className="uk-section md-bg-white">
          <div className="uk-container">
            <div data-uk-grid>
              <div className="uk-width-1-2@m">
                <div className="uk-card cascade-card-wider">
                  <div className="cascade-card-overlay uk-card-header md-bg-indigo-900">
                    <div className="uk-grid-small uk-flex-middle uk-grid" uk-grid="">
                      <div className="uk-width-auto uk-first-column">
                        <img className="uk-border-circle" width="40" height="40" src={MoniFoto} />
                      </div>
                      <div className="uk-width-expand">
                        <h3 className="uk-card-title uk-margin-remove-bottom md-color-white">Como comprar?</h3>
                      </div>
                    </div>
                  </div>
                  <div className="cascade-card-content md-bg-white">
                    <div className="uk-card-body">
                      <p>El proceso para comprar una vivienda puede parecer complicado, pero si toma las cosas paso a paso, pronto tendrá en sus manos las llaves para su propia casa!</p>
                      <ul align='justify'>
                        <li>Es importante tener a la mano un Estudio de Títulos.</li>
                        <li>El abogado de su elección y confianza elaborará una propuesta.</li>
                        <li>La entrega de una constancia respecto de tributos municipales y arbitrios que correspondan al mismo.</li>
                        <li>Constate la zonificación municipal en la cual se encuentre la propiedad que desea comprar.</li>
                        <li>Investigue en la Municipalidad el uso previo ha tenido el suelo sobre el cual está la casa o departamento que está comprando.</li>
                        <li>Que el comprador le haga entrega de los recibos de agua, luz, cable, internet y teléfonos debidamente cancelados.</li>
                        <li>Infórmese con su abogado los costos tributarios de la compraventa.</li>
                        <li>Si compra la casa, departamento o terreno con un crédito hipotecario, pídale a su abogado que le explique con claridad cuales son sus derechos y las obligaciones que asumirá con el banco.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="uk-width-1-2@m">
                <div className="uk-card cascade-card-wider">
                  <div className="cascade-card-overlay uk-card-header md-bg-indigo-900">
                    <div className="uk-grid-small uk-flex-middle uk-grid" uk-grid="">
                      <div className="uk-width-auto uk-first-column">
                        <img className="uk-border-circle" width="40" height="40" src={MoniFoto} />
                      </div>
                      <div className="uk-width-expand">
                        <h3 className="uk-card-title uk-margin-remove-bottom md-color-white">Como vender?</h3>
                      </div>
                    </div>
                  </div>
                  <div className="cascade-card-content md-bg-white">
                    <div className="uk-card-body">
                      <p>El proceso para comprar una vivienda puede parecer complicado, pero si toma las cosas paso a paso, pronto tendrá en sus manos las llaves para su propia casa!</p>
                      <ul align='justify'>
                        <li>Es importante tener a la mano un Estudio de Títulos.</li>
                        <li>El abogado de su elección y confianza elaborará una propuesta.</li>
                        <li>La entrega de una constancia respecto de tributos municipales y arbitrios que correspondan al mismo.</li>
                        <li>Constate la zonificación municipal en la cual se encuentre la propiedad que desea comprar.</li>
                        <li>Investigue en la Municipalidad el uso previo ha tenido el suelo sobre el cual está la casa o departamento que está comprando.</li>
                        <li>Que el comprador le haga entrega de los recibos de agua, luz, cable, internet y teléfonos debidamente cancelados.</li>
                        <li>Infórmese con su abogado los costos tributarios de la compraventa.</li>
                        <li>Si compra la casa, departamento o terreno con un crédito hipotecario, pídale a su abogado que le explique con claridad cuales son sus derechos y las obligaciones que asumirá con el banco.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <FrontFooter />
      </div>
    )
  }
}
export default Nosotros;