import React, { Component } from 'react';
import MDUIkit from 'md-uikit';

import { Link } from "react-router-dom";
import AppSettings from '../../app_settings';

class Propiedades extends Component {
  state = {
    propertyInfo: [],
    propertyFeatures: [],
    propertyServices: [],
    propertyImages: [],
    loading: true
  }
  _fetchPropertyDetail({ propertyId }) {
    fetch(`${AppSettings.connectionString}/admin/API/script_getInmuebleById.php?InmuebleId=${propertyId}`)
      .then(res => res.json())
      .then(data => {
        try {
          //console.log('_fetchPropertyDetail: ' + propertyId);
          data.PrecioVenta = data.PrecioVenta.toLocaleString();
          data.PrecioVentaUSD = data.PrecioVentaUSD.toLocaleString();
          data.PrecioAlquiler = data.PrecioAlquiler.toLocaleString();
          data.PrecioAlquilerUSD = data.PrecioAlquilerUSD.toLocaleString();
          this.setState({ propertyInfo: data, loading: false })
        } catch (error) {
          console.log(error);
        }
      })
  }
  _fetchPropertyFeatures({ propertyId }) {
    fetch(`${AppSettings.connectionString}/admin/API/script_getCaracteristicasByInmuebleId.php?InmuebleId=${propertyId}`)
      .then(res => res.json())
      .then(data => {
        /* console.log('_fetchPropertyFeatures');
        console.log(propertyId); */
        this.setState({ propertyFeatures: data, loading: false })
      })
  }
  _fetchPropertyServices({ propertyId }) {
    fetch(`${AppSettings.connectionString}/admin/API/script_getServiciosByInmuebleId.php?InmuebleId=${propertyId}`)
      .then(res => res.json())
      .then(data => {
        this.setState({ propertyServices: data, loading: false })
      })
  }
  _fetchPropertyImages({ propertyId }) {
    const req = {
      "inmuebleId": propertyId
    }
    fetch(`${AppSettings.connectionString}/admin/API/inmueble_images_by_id.php`, {
      method: 'POST',
      body: JSON.stringify(req)
    }).then(res => res.json())
      .then(data => {
        console.log('script_getPropertyImages');
        console.log(data)
        this.setState({ propertyImages: data, loading: false })
      })
  }
  goBack = (e) => {
    e.preventDefault();
    console.log("GO back")
    this.props.history.goBack();
  }
  componentDidMount() {
    MDUIkit.components();
    const { propiedadId } = this.props;
    //console.log(this.props.match.params);
    this._fetchPropertyDetail({ propertyId: propiedadId });
    this._fetchPropertyFeatures({ propertyId: propiedadId });
    this._fetchPropertyServices({ propertyId: propiedadId });
    this._fetchPropertyImages({ propertyId: propiedadId });
  }

  render() {
    const { propertyInfo } = this.state;
    const { propertyFeatures } = this.state;
    const { propertyServices } = this.state;
    const { propertyImages } = this.state;
    return (
      <div className="uk-width-1-1">
        <div className="uk-card md-bg-white">
          <div className="uk-card-body">
            <div className="uk-grid-divider uk-grid-small" data-uk-grid>
              <div className="uk-width-1-3@m">
                <div className="uk-position-relative uk-visible-toggle uk-light" data-uk-slideshow>
                  <ul className="uk-slideshow-items">
                    {propertyImages.length > 0 && propertyImages.map((image) =>
                      <li>
                        <img src={`${AppSettings.connectionString}/admin${image.imageUrl}`} alt="" data-uk-cover />
                      </li>
                    )}
                  </ul>
                  <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-previous data-uk-slideshow-item="previous"></a>
                  <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-next data-uk-slideshow-item="next"></a>
                </div>
                {(propertyInfo.VentaActiva != '0') &&
                  <div className='uk-grid-small' data-uk-grid>
                    {(Number.parseFloat(propertyInfo.PrecioVenta) > 0) ? (
                      <div className="uk-width-1-1@m">
                        <h4 className='uk-margin-small-top uk-margin-remove-bottom'><strong>Venta: </strong>  S/. {propertyInfo.PrecioVenta}
                        </h4>
                        {(Number.parseFloat(propertyInfo.PrecioVentaUSD) > 0) &&
                          <span className='uk-text-meta uk-margin-remove-top'> (USD {propertyInfo.PrecioVentaUSD})</span>
                        }
                      </div>
                    ) : (
                        <div className="uk-width-1-1@m">
                          <h4 className='uk-margin-small-top uk-margin-remove-bottom'><strong>Venta: </strong> USD {propertyInfo.PrecioVentaUSD}
                          </h4>
                        </div>
                      )
                    }
                  </div>
                }
                {(propertyInfo.AlquilerActivo != '0') &&
                  <div className='uk-grid-small' data-uk-grid>
                    {(Number.parseFloat(propertyInfo.PrecioAlquiler) > 0) ? (
                      <div className="uk-width-1-1@m">
                        <h4 className='uk-margin-small'><strong>Alquiler: </strong>  S/. {propertyInfo.PrecioAlquiler}
                        </h4>
                        {(Number.parseFloat(propertyInfo.PrecioAlquilerUSD) > 0) &&
                          <span className='uk-text-meta'> (USD {propertyInfo.PrecioAlquilerUSD})</span>
                        }
                      </div>
                    ) : (
                        <div className="uk-width-1-1@m">
                          <h4 className='uk-margin-small-top uk-margin-remove-bottom'><strong>Alquiler: </strong> USD {propertyInfo.PrecioAlquilerUSD}
                          </h4>
                        </div>
                      )
                    }
                  </div>
                }
                {(propertyInfo.Mantenimiento != '0') &&
                  <div className='uk-grid-small' data-uk-grid>
                    <div className="uk-width-1-1@m">
                      <h5 className='uk-margin-small'>+ S/. {propertyInfo.Mantenimiento} Mantenimiento</h5>
                    </div>
                  </div>
                }
              </div>
              <div className="uk-width-2-3@m">
                <Link to={`/detalle_propiedad/${propertyInfo.InmuebleId}`}>
                  <h3 className="uk-margin-remove-bottom">{propertyInfo.Titulo}</h3>
                  <p className="uk-text-meta uk-margin-remove-top"><time dateTime="2016-04-01T19:00">{propertyInfo.DistritoNombre} - {propertyInfo.DepartamentoNombre}</time></p>

                  <span className="uk-badge md-bg-blue-grey-700">{propertyInfo.MetrosTotales} m² totales</span>
                  <span className="uk-badge md-bg-blue-grey-700">{propertyInfo.MetrosConstruidos} m² techados</span>
                  <span className="uk-badge md-bg-blue-grey-700">{propertyInfo.Dormitorios} Dormitorios</span>
                  <span className="uk-badge md-bg-blue-grey-700">{propertyInfo.BanosCompletos} Baños</span>
                  <span className="uk-badge md-bg-blue-grey-700">{propertyInfo.Estacionamientos} Estacionamiento</span>
                  <p className="md-color-blue-grey-900" align="justify">{propertyInfo.Descripcion && propertyInfo.Descripcion.length > 280 ?
                    propertyInfo.Descripcion.substring(0, 280) + "..." :
                    propertyInfo.Descripcion
                  }</p>
                  <div className="uk-card-footer">
                    <div className="md-card-toolbar-actions">
                      <Link to={`/detalle_propiedad/${propertyInfo.InmuebleId}`} className="md-btn md-btn-text md-btn-secondary md-btn-wave">VER Detalles</Link>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}
export default Propiedades;