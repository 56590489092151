import React, { Component } from 'react';
import MDUIkit from 'md-uikit';

// Components
import FrontHeader from '../Header/Header';
import FrontFooter from '../Footer/Footer';
import MapContainer from '../../../components/map-container/map-container.component';

// Images
import MoniFoto from '../../../assets/img/profile.jpg';
import portada1 from '../../../assets/img/portada_1.jpg';
import PageHeader from '../Components/PageHeader';

class Contacto extends Component {
  componentDidMount() {
    MDUIkit.components();
  }
  render() {
    return (
      <div>
        <FrontHeader />
        <PageHeader title="Contacto" />
        <div className="uk-container md-bg-white">
        <br/>
          <div className="" data-uk-grid>
            <div className="uk-width-1-2@m">
              <h3>Contactanos</h3>
              <p>Dejanos un mensaje, en breve contestaremos a su consulta</p>
              <div className="uk-grid-small uk-flex-middle uk-grid" uk-grid="">
                <div className="uk-width-auto uk-first-column">
                  <img className="uk-border-circle" width="40" height="40" src={MoniFoto} />
                </div>
                <div className="uk-width-expand">
                  <h3 className="uk-card-title uk-margin-remove-bottom">Monica Tejada</h3>
                  <p className="uk-text-meta uk-margin-remove-top"><time dateTime="2016-04-01T19:00">Gerente General</time></p>
                </div>
              </div>
              <br />
              <div className="md-input-wrapper">
                <label>Nombre:</label>
                <input type="text" className="md-input" required />
                <span className="md-input-bar"></span>
              </div>
              <div className="md-input-wrapper">
                <label>Email:</label>
                <input type="text" className="md-input" required />
                <span className="md-input-bar"></span>
              </div>
              <div className="md-input-wrapper">
                <label>Asunto:</label>
                <input type="text" className="md-input" />
                <span className="md-input-bar"></span>
              </div>
              <div className="md-input-wrapper md-input-textarea">
                <label>Mensaje</label>
                <textarea className="md-input"></textarea>
              </div>
            </div>
            <div className="uk-width-1-2@m">
              <MapContainer/>
            </div>
          </div>
        </div>
        <FrontFooter />
      </div>
    )
  }
}
export default Contacto;