import React from 'react'
import portada1 from '../../../assets/img/portada_1.jpg';

const PageHeader = ({ title }) => {
    return (
        <div style={{ backgroundImage: `url(${portada1})`, backgroundSize: "cover" }}>
            <div className="rgba-black-light">
                <div className="uk-section">
                    <div className="uk-container">
                        <div className="uk-grid-small" data-uk-grid data-uk-scrollspy="cls: uk-animation-fade; target: .uk-text-center; delay: 200;">
                            <div className="uk-width-1-1 uk-text-center">
                                <h2 className="md-color-white">{title}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageHeader
