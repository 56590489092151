import { UserActionTypes } from './user.types';
const INITIAL_STATE = {
    currentUser: JSON.parse(sessionStorage.getItem("currentUser"))
}
const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UserActionTypes.SET_CURRENT_USER:
            sessionStorage.setItem('currentUser', JSON.stringify(action.payload));
            return {
                ...state,
                currentUser: action.payload
            };
        case "LOGOUT":
            sessionStorage.removeItem('currentUser');
            return {
                ...state,
                currentUser: null
            };
        default:
            return state;
    }
}
export default userReducer;