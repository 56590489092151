import React, { useState, useEffect } from 'react';
import { ReactSortable } from "react-sortablejs";

import MDUIkit from 'md-uikit';
import UIkit from 'uikit';
import axios from 'axios';
import { Link, useParams, useHistory } from "react-router-dom";

import AppSettings from '../../../app_settings';
const Step3 = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [images_array, setImages_array] = useState([]);

  const { propiedadId } = useParams();
  const history = useHistory();
  useEffect(() => {

    MDUIkit.components();
    _fetchPropertiesImages();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    UIkit.util.on('#sortable', 'moved', (item) => {
      console.log(item);
      checkOrder(images_array, item);
    });
  }, [images_array]);
  function checkOrder(newImages, item) {
    console.log(newImages);
    UIkit.notification(`"${item.detail[1].id}" was moved.`, 'success');
  }

  const fileChangedHandler = event => {
    console.log(event.target.files);
    setSelectedFile(event.target.files);
  }
  function _fetchPropertiesImages() {
    console.log('_fetchPropertiesImages ');
    //var modal = UIkit.modal.dialog('<div class=\'uk-modal-body\'><p>Cargando imagenes...</p></div>', { 'bgClose': false, 'escClose': false });
    const req = {
      "inmuebleId": propiedadId
    }
    fetch(`${AppSettings.connectionString}/admin/API/inmueble_images_by_id.php`, {
      method: 'POST',
      body: JSON.stringify(req)
    })
      .then(res => res.json())
      .then(data => {

        setImages_array(data);
        console.log(data);
        //modal.hide();
      })
      .catch(error => console.error('Error:', error));
  }
  function _fetchDeleteImage(id, fileName) {
    const url = `${AppSettings.connectionString}/admin/API/script_deletePropertyImage.php?InmuebleId=${propiedadId}&fileName=${fileName}&RelationId=${id}`;
    console.log(url);
    fetch(url)
      .then(res => res.text())
      .then(data => {
        console.log(data);
        _fetchPropertiesImages();
      })
      .catch(error => console.error('Error:', error));
  }
  const deleteHandler = (id, fileName) => (e) => {
    e.preventDefault();
    _fetchDeleteImage(id, fileName);
  }
  async function uploadHandler() {
    const formData = new FormData();

    //var modal = UIkit.modal.dialog('<div class=\'uk-modal-body\'><p>Subiendo imagenes...</p></div>', { 'bgClose': false, 'escClose': false });
    for (var x = 0; x < selectedFile.length; x++) {
      formData.append(
        'myFile_' + [x],
        selectedFile[x],
        selectedFile[x].name
      );
    }

    formData.append('id', propiedadId);

    try {
      const res = await axios.post(`${AppSettings.connectionString}/admin/API/script_insertPropertyImage.php`, formData, {
        onUploadProgress: progressEvent => {
          console.log(progressEvent.loaded / progressEvent.total)
        }
      });
      console.log('resultado');
      console.log(res.data);
      _fetchPropertiesImages();
    } catch (error) {
      console.log(error);
    }

  }

  const onClickUploadImages = () => {
    if (selectedFile != null) {
      uploadHandler()
    } else {
      alert("No hay imagenes para subir");
    }
  }

  async function sendInfo() {

    console.log("sendInfo");
    try {

      const data = {
        images: images_array
      }
      console.log(data);
      const response = await fetch(`${AppSettings.connectionString}/admin/API/inmueble_images_sort.php`, {
        method: 'POST',
        body: JSON.stringify(data)
      })

      const res = await response.json();

      console.log(res);
      history.push("/admin/avisos");
    } catch (error) {
      console.log(error);
    }
  }
  const onSendInfo = () => {
    sendInfo();

  }
  return (
    <div>
      <ul class="stepper stepper-horizontal">
        <li class="completed">
          <Link to={`../paso1/${propiedadId}`}>
            <span class="circle">1</span>
            <span class="label">Datos Principales</span>
          </Link>
        </li>
        <li class="completed">
          <Link to={`../paso2/${propiedadId}`}>
            <span class="circle">2</span>
            <span class="label">Caracteristicas</span>
          </Link>
        </li>
        <li class="active">
          <a href="#!">
            <span class="circle">3</span>
            <span class="label">Multimedia</span>
          </a>
        </li>
      </ul>
      <div data-uk-grid >
        <div className="uk-width-1-1">
          <h5>Agregar Imagenes</h5>
          <div data-uk-grid className="uk-grid-small">
            <div className="uk-width-1-1@m">
              <input type="file" onChange={fileChangedHandler} multiple /><br /><br />
              <button className="md-btn md-btn-small md-btn-secondary" onClick={onClickUploadImages}>Subir Imagenes</button>
            </div>
            <div className="uk-width-1-1@m">
              <h5> Imagenes Publicadas</h5>
              {images_array.length > 0 &&
                <ReactSortable list={images_array} setList={setImages_array} className="uk-grid" data-uk-grid animation={200}>
                  {images_array.map((image, key) =>
                    <div key={key} className="uk-width-1-4@m uk-width-1-2@s uk-text-center">
                      <div className="uk-card uk-card-default uk-card-body md-bg-blue-grey-50">
                        <img className="object-fit" style={{ height: "150px" }} src={`${AppSettings.connectionString}/admin${image.imageUrl}`} alt="" />
                        <button className="md-btn md-btn-small md-btn-secondary" onClick={deleteHandler(image.id, image.imageUrl)}><i className="material-icons">delete</i></button>
                      </div>
                    </div>
                  )}
                </ReactSortable>
              }
              <div className='uk-padding-small uk-align-right'>
                <button onClick={onSendInfo} className="md-btn md-btn-secondary"> PUBLICAR </button>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div >
  )
}
export default Step3;