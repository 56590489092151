import React, { Component } from 'react';
import MDUIkit from 'md-uikit';

// Components
import FrontHeader from '../Header/Header';
import FrontFooter from '../Footer/Footer';

// Images
import portada1 from '../../../assets/img/portada_1.jpg';
import imgANTIGUEDAD from '../../../assets/img/antiguedad_img.jpg';
import imgMBANIOS from '../../../assets/img/mbanios_img.jpg';
import imgPARK from '../../../assets/img/estacionamientos_img.jpg';
import imgBANIOS from '../../../assets/img/banios_img.jpg';
import imgSTOTAL from '../../../assets/img/stotal_img.jpg';

import AppSettings from '../../../app_settings';
import PageHeader from '../Components/PageHeader';

class Propiedades extends Component {
  state = {
    propertyInfo: [],
    propertyFeatures: [],
    propertyServices: [],
    propertyImages: [],
    loading: true
  }
  _fetchPropertyDetail({ propertyId }) {
    fetch(`${AppSettings.connectionString}/admin/API/script_getInmuebleById.php?InmuebleId=${propertyId}`)
      .then(res => res.json())
      .then(data => {
        data.PrecioVenta = data.PrecioVenta.toLocaleString();
        data.PrecioVentaUSD = data.PrecioVentaUSD.toLocaleString();
        data.PrecioAlquiler = data.PrecioAlquiler.toLocaleString();
        data.PrecioAlquilerUSD = data.PrecioAlquilerUSD.toLocaleString();
        this.setState({ propertyInfo: data, loading: false })
      })
  }
  _fetchPropertyFeatures({ propertyId }) {
    fetch(`${AppSettings.connectionString}/admin/API/script_getCaracteristicasByInmuebleId.php?InmuebleId=${propertyId}`)
      .then(res => res.json())
      .then(data => {
        console.log('_fetchPropertyFeatures');
        console.log(propertyId);
        this.setState({ propertyFeatures: data, loading: false })
      })
  }
  _fetchPropertyServices({ propertyId }) {
    fetch(`${AppSettings.connectionString}/admin/API/script_getServiciosByInmuebleId.php?InmuebleId=${propertyId}`)
      .then(res => res.json())
      .then(data => {
        this.setState({ propertyServices: data, loading: false })
      })
  }
  _fetchPropertyImages({ propertyId }) {
    const req = {
      "inmuebleId": propertyId
    }
    fetch(`${AppSettings.connectionString}/admin/API/inmueble_images_by_id.php`, {
      method: 'POST',
      body: JSON.stringify(req)
    }).then(res => res.json())
      .then(data => {
        console.log('script_getPropertyImages');
        console.log(data)
        this.setState({ propertyImages: data, loading: false })
      })
  }
  goBack = (e) => {
    e.preventDefault();
    console.log("GO back")
    this.props.history.goBack();
  }
  componentDidMount() {
    MDUIkit.components();
    const { propiedadId } = this.props.match.params;
    //console.log(this.props.match.params);
    this._fetchPropertyDetail({ propertyId: propiedadId });
    this._fetchPropertyFeatures({ propertyId: propiedadId });
    this._fetchPropertyServices({ propertyId: propiedadId });
    this._fetchPropertyImages({ propertyId: propiedadId });
  }
  render() {
    const { propertyInfo } = this.state;
    const { propertyFeatures } = this.state;
    const { propertyServices } = this.state;
    const { propertyImages } = this.state;
    return (
      <div>
        <FrontHeader />
        <PageHeader title="Propiedades" />
        <div className='uk-container'>
          <br />
          <div data-uk-grid>
            <div className="uk-width-2-3@m">
              <div className="uk-card md-bg-white">
                <div className="uk-card-body">
                  <button className='md-btn md-color-white md-btn-primary' onClick={this.goBack}><i class="material-icons md-color-white">keyboard_arrow_left</i> Volver</button>
                  <div className="uk-position-relative uk-visible-toggle uk-light" data-uk-slideshow>
                    <br />
                    <ul className="uk-slideshow-items">
                      {propertyImages.length > 0 && propertyImages.map((image) =>
                        <li>
                          <img src={`${AppSettings.connectionString}/admin${image.imageUrl}`} alt="" data-uk-cover />
                        </li>
                      )}
                    </ul>
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-previous data-uk-slideshow-item="previous"></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-next data-uk-slideshow-item="next"></a>

                  </div>
                  <h3 className="uk-margin-remove-bottom">{propertyInfo.TipoInmubleNombre} · {propertyInfo.MetrosConstruidos} m² · {propertyInfo.Dormitorios} Dormitorios</h3>
                  <p class="uk-text-meta uk-margin-remove-top"><time datetime="2016-04-01T19:00">{propertyInfo.DistritoNombre} - {propertyInfo.DepartamentoNombre}</time></p>
                  <hr />
                  <h3 className="uk-margin-remove-bottom">{propertyInfo.Titulo}</h3>

                  <p>{propertyInfo.Descripcion}</p>
                  <div data-uk-grid>
                    <div className="uk-width-1-2 uk-width-1-4@m">
                      <img src={imgSTOTAL} width="45px" />
                      <strong>{propertyInfo.MetrosTotales}m²</strong>
                      <br />
                      Superficie total</div>
                    <div className="uk-width-1-2 uk-width-1-4@m"><img src={imgSTOTAL} width="45px" /><strong>{propertyInfo.MetrosConstruidos}m²</strong>
                      <br /> Superficie techada</div>
                    <div className="uk-width-1-2 uk-width-1-4@m"><img src={imgBANIOS} width="45px" /><strong>{propertyInfo.BanosCompletos}</strong>
                      <br /> Baño(s)</div>
                    <div className="uk-width-1-2 uk-width-1-4@m"><img src={imgPARK} width="45px" /><strong>{propertyInfo.Estacionamientos}</strong>
                      <br /> Estacionamiento</div>
                    <div className="uk-width-1-2 uk-width-1-4@m"><img src={imgSTOTAL} width="45px" /><strong>{propertyInfo.Dormitorios}</strong>
                      <br /> Dormitorios</div>
                    <div className="uk-width-1-2 uk-width-1-4@m"><img src={imgMBANIOS} width="45px" /><strong>{propertyInfo.BanosMedios}</strong>
                      <br /> Medio Baño</div>
                    <div className="uk-width-1-2 uk-width-1-4@m"><img src={imgANTIGUEDAD} width="45px" /><strong>{propertyInfo.Antiguedad}</strong>
                      <br /> Antigüedad</div>
                  </div>
                  <hr />
                  <h6>Caracteristicas Generales</h6>
                  <ul>
                    {propertyServices.map((property_service) =>
                      <li>{property_service.CaracteristicaNombre}</li>
                    )}

                  </ul>
                  <hr />
                  <h6>Servicios</h6>
                  <ul>
                    {propertyFeatures.map((property_feature) =>
                      <li>{property_feature.CaracteristicaNombre}</li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="uk-width-1-3@m">
              <div uk-sticky="offset: 80; top : #footer">
                <div className="uk-card md-bg-white">
                  <div className="uk-card-body">
                    {(propertyInfo.VentaActiva != '0') &&
                      <div className='uk-grid-small' data-uk-grid>
                        <div className="uk-width-1-1@m uk-text-center">
                          <h3 align='center' className='uk-text-bold'>Venta</h3>
                        </div>
                        {(Number.parseFloat(propertyInfo.PrecioVenta) > 0) &&
                          <div className="uk-width-1-1@m uk-text-center">
                            <p className='uk-text-large'>S/. {propertyInfo.PrecioVenta}</p>
                          </div>
                        }
                        {(Number.parseFloat(propertyInfo.PrecioVentaUSD) > 0) &&
                          <div className="uk-width-1-1@m uk-text-center">
                            <p className='uk-text-large'>USD {propertyInfo.PrecioVentaUSD}</p>
                          </div>
                        }
                      </div>
                    }
                    {(propertyInfo.AlquilerActivo != '0') &&
                      <div className='uk-grid-small' data-uk-grid>
                        <div className="uk-width-1-1@m">
                          <h3 align='center' className='uk-text-bold'>Alquiler</h3>
                        </div>
                        {Number.parseFloat(propertyInfo.PrecioAlquiler) > 0 &&
                          <div className="uk-width-1-2@m">
                            <p className='uk-text-large'>S/. {propertyInfo.PrecioAlquiler}</p>
                          </div>
                        }
                        {Number.parseFloat(propertyInfo.PrecioAlquilerUSD) > 0 &&
                          <div className="uk-width-1-2@m">
                            <p className='uk-text-large'>USD {propertyInfo.PrecioAlquilerUSD}</p>
                          </div>
                        }
                      </div>
                    }
                  </div>
                </div>
                <br />
                <div className="uk-card md-bg-white">
                  <div className="uk-card-body">
                    <div data-uk-grid>
                      <div className="uk-width-1-1@m">
                        <h4 className='uk-text-bold' align="center">¿Deseas recibir más información?</h4>
                        {/* <p>Dejanos tus datos para contactarte</p>
                        <div class="md-input-wrapper md-input-dense">
                          <label>Email:</label>
                          <input type="text" class="md-input" />
                          <span class="md-input-bar"></span>
                        </div>
                        <div class="md-input-wrapper md-input-dense">
                          <label>Nombre:</label>
                          <input type="text" class="md-input" />
                          <span class="md-input-bar"></span>
                        </div>
                        <div class="md-input-wrapper md-input-dense">
                          <label>Telefono:</label>
                          <input type="text" class="md-input" />
                          <span class="md-input-bar"></span>
                        </div>
                        <a class="md-btn md-btn-facebook md-btn-block ">Enviar informacion</a> */}
                        <span>Visitanos en :</span>
                        <h4 className='uk-margin-remove-top'>Edificio Magnus Torre B Dpto 508 - Cayma</h4>
                        <span>Escribenos a :</span>
                        <div style={{ overflow: "auto" }}>
                          <h4 className='uk-margin-remove-top'><a href="emailto:958578777">ventas@inmobiliariamonicatejada.com</a></h4>
                        </div>
                        <span>Llamanos a:</span>
                        <h4 className='uk-margin-remove-top'><a href="tel:958578777">958 578 777</a></h4>
                        <h4 className='uk-margin-remove-top'><a href="tel:959386208">959 386 208</a></h4>
                        <h4 className='uk-margin-remove-top'><a href="tel:959672804">959 672 804</a></h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <FrontFooter />
      </div>
    )
  }
}
export default Propiedades;