import React, { Component } from 'react';

class NotFound extends Component {
  render() {
    return (
      <div>
        <h3>NotFound</h3>
      </div>
    )
  }
}
export default NotFound;