import React, { Component } from 'react';

import properties_image from '../../../assets/img/properties.jpg';
import banners_img from '../../../assets/img/banners_img.jpg';
import { Link } from "react-router-dom";

class AdminPanel extends Component {
    state = {
        isLogged: true
    }
    render() {
        return (
            <div className="uk-grid-match" data-uk-grid>
                <div className="uk-width-1-2@s uk-width-1-4@m" align="center">
                    <div className="md-card">
                        <Link to="/admin/avisos">
                            <div className="md-card-content">
                                <h4>Mis avisos</h4>
                                <img src={properties_image} alt="Imagen Principal" />
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="uk-width-1-2@s uk-width-1-4@m" align="center">
                    <div className="md-card">
                        <Link to="/admin/config">
                            <div className="md-card-content">
                                <h4>Banners</h4>
                                <img src={banners_img} alt="Imagen Principal" />
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}
export default AdminPanel;