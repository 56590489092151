import React, { useState, useEffect } from 'react';
import MDUIkit from 'md-uikit';
import UIkit from 'uikit';
import { Link } from "react-router-dom";

import PropertyInfoAdmin from '../../../components/property/info-admin.component';
import { connect } from 'react-redux';
import { setCurrentProperties } from '../../../redux/properties/properties.actions';

import AppSettings from '../../../app_settings';
import Grid from '@material-ui/core/Grid'
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress } from '@material-ui/core';

const Ads = () => {

  const [isLoading, setIsLoading] = useState(true)
  const [tipoInmueble, setTipoInmueble] = useState("0")
  const dispatch = useDispatch();
  const properties = useSelector(state => state.properties.currentProperties);

  useEffect(() => {
    _fetchProperties();
  }, [])

  async function _fetchProperties() {

    console.log("_fetchProperties");
    const response = await fetch(`${AppSettings.connectionString}/admin/API/script_getInmuebles.php`)
    const data = await response.json();
    for (let index = 0; index < data.length; index++) {
      data[index].EstadoId = data[index].EstadoId == 1 ? true : false;
    }
    console.log(data);
    dispatch(setCurrentProperties(data));
    setIsLoading(false);

  }
  const CheckTipoVivienda = (Tipo) => (e) => {
    setTipoInmueble(Tipo);
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Link className='md-btn md-bg-indigo-700 md-color-white' to='/admin/nuevoaviso/paso1/0'><i className="material-icons">add</i>  Nuevo aviso</Link>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <div className='uk-card uk-card-body md-bg-white'>
              <h5>Tipo de Inmueble</h5>
              <ul className='uk-list'>
                <div className="md-radio md-radio-inline">
                  <input id='radio_todos' name='previos_name_radio_group' checked={tipoInmueble == '0' && true} type="radio" onChange={CheckTipoVivienda(0)} />
                  <label htmlFor="radio_todos">Todos</label>
                </div>
                <div className="md-radio md-radio-inline">
                  <input id='radio_venta' name='previos_name_radio_group' checked={tipoInmueble == '1' && true} type="radio" onChange={CheckTipoVivienda(1)} />
                  <label htmlFor="radio_venta">Venta</label>
                </div>
                <div className="md-radio md-radio-inline">
                  <input id='radio_alquiler' name='previos_name_radio_group' checked={tipoInmueble == '2' && true} type="radio" onChange={CheckTipoVivienda(2)} />
                  <label htmlFor="radio_alquiler">Alquiler</label>
                </div>
                {/* <li>Casa (X)</li>

                  <li>Departamento (x)</li> */}
              </ul>
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            <div className='uk-grid-small' data-uk-grid>
              {!isLoading ?
                (properties && properties.map((property, key) => (
                  (tipoInmueble == '1' && property.VentaActiva == '1') ? (
                    <PropertyInfoAdmin key={key} propertyDetails={property} fetchProperties={_fetchProperties} />
                  ) : (
                    (tipoInmueble == '2' && property.AlquilerActivo == '1') ? (
                      <PropertyInfoAdmin key={key} propertyDetails={property} fetchProperties={_fetchProperties} />
                    ) : (
                      (tipoInmueble == '0' &&
                        <PropertyInfoAdmin key={key} propertyDetails={property} fetchProperties={_fetchProperties} />
                      )
                    )
                  )
                )
                )
                ) :
                <div className="uk-width-1-1">
                  <Box p={2} justifyContent="center" display="flex">
                    <CircularProgress />
                  </Box>
                </div>
              }
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Ads
