import React, { Component, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { setCurrentUser } from './redux/user/user.actions';
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

// Components
import LoginPage from './views/AdminPanel/LoginPage/LoginPage';
import AdminPanel from './views/AdminPanel/Panel/Panel';
import NotFound from './views/not-found';
import Home from './views/Website/Home/Home';
import Contacto from "./views/Website/Contact/Contact";
import Nosotros from "./views/Website/We/We";
import Servicios from "./views/Website/Services/Services";
import Propiedades from "./views/Website/Properties/Properties";
import DetallePropiedad from "./views/Website/Properties/Details";


import './App.css';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import { useClearCache } from 'react-clear-cache';
import settings from './app_settings.js';

let theme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: /* "#6200EE" */ "#689f38",
    },
  },
  typography: {
    fontFamily: [
      'Google Sans',
      'Arial',
      'serif',
    ].join(','),
  },
});

const App = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  useEffect(() => {
    console.log("version")
    console.log(settings.version)
    if (!isLatestVersion) {
      console.log("updated to the new version")
      emptyCacheStorage();
    }

  }, [])

  const currentUser = useSelector((state) => state.user.currentUser);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <HashRouter>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/admin' render={() =>
              currentUser ? (
                <AdminPanel />
              ) : (
                <Redirect to='/' />
              )
            } />
            <Route path='/login' render={() =>
              currentUser ? (
                <Redirect to='/admin' />
              ) : (
                <LoginPage />
              )
            } />
            <Route path='/contacto' component={Contacto} />
            <Route path='/nosotros' component={Nosotros} />
            <Route path='/servicios' component={Servicios} />
            <Route path='/propiedades/:type/:district/:page' component={Propiedades} />
            <Route path='/propiedades' component={Propiedades} />
            <Route path='/detalle_propiedad/:propiedadId' component={DetallePropiedad} />
            <Route component={NotFound} />
          </Switch>
        </HashRouter>
      </ThemeProvider>
    </div>
  )
}

export default App;
