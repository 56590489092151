import React, { Component } from 'react';
import { Route } from "react-router-dom";
/* import UIkit from 'uikit'; */

import 'uikit/dist/css/uikit.min.css';
import UIkit from 'uikit/dist/js/uikit.js';
import MDUIkit from 'md-uikit';
import Routes from '../routes.js';
/* Custom Routes */
import NuevoAviso from '../NewAd/NewAd';
import AdminHome from '../AdminHome/AdminHome';
import BannersInner from '../ConfigPage/BannersInner';

class MainContent extends Component {
  componentDidMount() {
  }
  render() {

    return (
      <main id="admin-main">
        <div className='uk-container'>
          {/* Menu Routes */}
          {Routes.map((route, key) =>
            <div key={key}>
              <Route path={route.path} component={route.component} />
              {route.submenu &&
                route.submenu.map((inner_route, key) =>
                  <Route path={inner_route.path} component={inner_route.component} />
                )
              }
            </div>
          )}
          {/* Custom Routes */}
          <Route path='/admin' exact component={AdminHome} />
          <Route path='/admin/nuevoaviso/' component={NuevoAviso} />
          <Route path='/admin/banners_detail/:id' component={BannersInner} />
        </div>
      </main>
    )
  }
}

export default MainContent;