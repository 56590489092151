import React, { useState, useEffect } from 'react';
import MDUIkit from 'md-uikit';
import UIkit from 'uikit';
import { Link } from "react-router-dom";


import AppSettings from '../../app_settings';
import Grid from '@material-ui/core/Grid'
import { useDispatch, useSelector } from "react-redux";
import { Box, FormControlLabel, Switch } from '@material-ui/core';

const PropertyInfoAdmin = ({ propertyDetails, fetchProperties }) => {

  const dispatch = useDispatch();
  const [propertyInfo, setPropertyInfo] = useState({
    EstadoId: false
  });

  useEffect(() => {
    MDUIkit.components();
    setPropertyInfo(propertyDetails);
  }, [propertyDetails])

  function updatePropertyInfo(newEstadoId) {
    var formData = new FormData();
    formData.append('InmuebleId', propertyInfo.InmuebleId);
    formData.append('Titulo', propertyInfo.Titulo);
    formData.append('Descripcion', propertyInfo.Descripcion);
    formData.append('TipoInmuebleId', propertyInfo.TipoInmuebleId);
    formData.append('Antiguedad', propertyInfo.Antiguedad);
    formData.append('Dormitorios', propertyInfo.Dormitorios);
    formData.append('Estacionamientos', propertyInfo.Estacionamientos);
    formData.append('BanosCompletos', propertyInfo.BanosCompletos);
    formData.append('BanosMedios', propertyInfo.BanosMedios);
    formData.append('MetrosConstruidos', propertyInfo.MetrosConstruidos);
    formData.append('MetrosTotales', propertyInfo.MetrosTotales);
    formData.append('DistritoId', propertyInfo.DistritoId);
    formData.append('EstadoId', newEstadoId);
    formData.append('PrecioVenta', propertyInfo.PrecioVenta);
    formData.append('PrecioAlquiler', propertyInfo.PrecioAlquiler);
    formData.append('VentaActiva', propertyInfo.VentaActiva);
    formData.append('AlquilerActivo', propertyInfo.AlquilerActivo);
    formData.append('PrecioVentaUSD', propertyInfo.PrecioVentaUSD);
    formData.append('PrecioAlquilerUSD', propertyInfo.PrecioAlquilerUSD);
    formData.append('Mantenimiento', propertyInfo.Mantenimiento);
    console.log(formData);

    const url = `${AppSettings.connectionString}/admin/API/script_UpdateInmueble.php`;
    if (propertyInfo.Titulo && propertyInfo.TipoInmuebleId) {
      fetch(url, {
        method: 'POST',
        body: formData
      })
        .then(res => res.json())
        .then(data => {
          console.log(data);
          if (data.RowsAffected) {
            UIkit.notification({
              message: 'Datos actualizados',
              status: 'success',
              pos: 'top-right'
            });
          }
        }).catch(function (error) {
          console.log(error);
          UIkit.notification({
            message: 'Error al guardar. Intentelo de nuevo.',
            status: 'danger',
            pos: 'top-right'
          });
        });
    } else {
      alert('Completa los espacios marcados en rojo');
    }
  }
  const onClickDelete = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append('InmuebleId', propertyInfo.InmuebleId);
    console.log('eliminar');
    const url = `${AppSettings.connectionString}/admin/API/script_DeleteInmueble.php`;
    if (window.confirm('Esta seguro que desea eliminar este inmueble?')) {
      fetch(url, {
        method: 'POST',
        body: formData
      })
        .then(res => res.json())
        .then(data => {
          console.log(data);
          if (data.RowsAffected) {
            console.log('afected');
            UIkit.notification({
              message: 'Inmueble Eliminado',
              status: 'success',
              pos: 'top-right'
            });
            fetchProperties();
          }
        }).catch(function (error) {
          console.log(error);
          UIkit.notification({
            message: 'Error al guardar. Intentelo de nuevo.',
            status: 'danger',
            pos: 'top-right'
          });
        });
    }
  }

  const handleSwitch = event => {
    const { checked, name } = event.target;
    console.log("handleSwitch", checked, name);
    updatePropertyInfo(checked);
    setPropertyInfo({ ...propertyInfo, [name]: checked });
  }
  return (
    <div className='uk-width-1-1'>
      <div className="uk-card md-bg-white">
        <div className="uk-card-body">
          <div data-uk-grid>
            <div className='uk-width-1-3@s'>
              <div className="uk-position-relative uk-visible-toggle uk-light" data-uk-slideshow>
                <ul className="uk-slideshow-items">
                  {propertyInfo.images && propertyInfo.images.map((image, key) =>
                    <li key={key}>
                      <img src="" src={`${AppSettings.connectionString}/admin${image.imageUrl}`} alt="" data-uk-cover />
                    </li>
                  )}
                </ul>
                <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-previous data-uk-slideshow-item="previous"></a>
                <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-next data-uk-slideshow-item="next"></a>
              </div>
            </div>
            <div className='uk-width-2-3@s'>

              <Link to={`nuevoaviso/paso1/${propertyInfo.InmuebleId}`}>
                <div className="uk-clearfix">
                  <div className="uk-float-left">
                    <h4>{propertyInfo.Titulo}</h4>
                  </div>
                  <div className="uk-float-right">
                    <div>
                      <button className="md-btn">Más opciones <i className="material-icons">&#xE313;</i></button>
                      <div uk-dropdown="mode: click" className="md-dropdown-dense">
                        <ul className="uk-nav uk-dropdown-nav">
                          <li><Link to={`nuevoaviso/paso1/${propertyInfo.InmuebleId}`}><i className="material-icons">edit</i> Editar</Link></li>
                          <li><a onClick={onClickDelete}><i className="material-icons">delete</i> Eliminar</a></li>
                          <li><Link to={`/detalle_propiedad/${propertyInfo.InmuebleId}`}><i className="material-icons">remove_red_eye</i> Vista Previa</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <p className='md-color-blue-grey-900' align='justify'>
                  {propertyInfo.Descripcion && propertyInfo.Descripcion.length > 80 ?
                    propertyInfo.Descripcion.substring(0, 180) + "..." :
                    propertyInfo.Descripcion
                  }
                </p>
              </Link>
              <Box align="right">
                <FormControlLabel
                  control={
                    <Switch
                      checked={propertyInfo.EstadoId}
                      onChange={handleSwitch}
                      name="EstadoId"
                      color="primary"
                    />
                  }
                  label={propertyInfo.EstadoId ? "Publicado" : "Borrador"}
                />
              </Box>
              {/* <div align='right' className="md-switch">
                <input type="checkbox" id={`checkbox_estado_${propertyInfo.InmuebleId}`} onChange={handleInput} checked={propertyInfo.EstadoId} />
                <label htmlFor={`checkbox_estado_${propertyInfo.InmuebleId}`} className="switch-label" >
                  <span className="toggle--on">Publicado</span>
                  <span className="toggle--off">Borrador</span>
                </label>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PropertyInfoAdmin
